import {OfferUsers} from "../types";
import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getAggregateBuyerByOrders} from "../graphql/queries";

export const useAggregateBuyerByOrdersFetch = (startDate: string, endDate: string, limit:number, country:string) => {
    return useQuery(['buyer-order-query', startDate, endDate, limit, country], async () => {
        const result: any = await API.graphql(graphqlOperation(getAggregateBuyerByOrders, {
            input: {
                startDate,
                endDate,
                country,
                limit
            },
        }));
        // console.log("result getAggregateBuyerByOrdersFetch: ", result);
        return result!.data!.getAggregateBuyerByOrders as OfferUsers[];
    });
};