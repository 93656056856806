import dayjs from "dayjs";
import {capitalize} from "lodash";

export interface ChartTitleProps {
    startDate?: string;
    endDate?: string;
    gender?: string;
    chartDefaultTitle: string;
    productName?: string;
    cooperativeName?:string;
    userAgeMin?: number;
    userAgeMax?: number;
    includeDate?: boolean;
    useSingleDate?:boolean;
    singleDate?: string;
}

export const generateOfferChartTitle = (params:ChartTitleProps) => {
    let userSection = 'by';

    if(params.gender) {
        userSection = userSection.concat(' ' + capitalize(params.gender));
    }

    if(params.cooperativeName) {
        userSection = userSection.concat(' ' + params.cooperativeName);
    }

    userSection = userSection.concat(' users');

    if(params.userAgeMin) {
        userSection = userSection.concat(`, older than ${params.userAgeMin}`)
    }

    if(params.userAgeMax) {
        userSection = userSection.concat(`, younger than ${params.userAgeMax}`)
    }

    if(!params.gender && !params.userAgeMin && !params.userAgeMax && !params.cooperativeName) {
        userSection = '';
    }

    let dateSection = '';

    if(params.useSingleDate) {
        if(params.singleDate) {
            const day = dayjs(params.singleDate);
            dateSection = dateSection.concat(`on ${day.toDate().toLocaleDateString()}`);
        }
    } else {
        if(params.startDate) {
            const startDay = dayjs(params.startDate);
            dateSection = dateSection.concat(`from ${startDay.toDate().toLocaleDateString()}`);
        }

        if(params.endDate) {
            const endDay = dayjs(params.endDate);
            dateSection = dateSection.concat(` up to ${endDay.toDate().toLocaleDateString()}`);
        }

        if(!params.includeDate) {
            dateSection = '';
        }
    }

    return `${params.chartDefaultTitle} ${dateSection} ${userSection}`;
};

export const generateUserChartTitle = (params:ChartTitleProps) => {
    let userSection = '';

    if(params.gender) {
        userSection = userSection.concat(capitalize(params.gender) + ' ');
    }

    if(params.cooperativeName) {
        userSection = userSection.concat(params.cooperativeName + ' ');
    }

    let afterSection  = ''

    console.log("params. usermaxAge", params.userAgeMax)

    if(params.userAgeMin) {
        afterSection = afterSection.concat(`, older than ${params.userAgeMin}`)
    }

    if(params.userAgeMax) {
        afterSection = afterSection.concat(`, younger than ${params.userAgeMax}`)
    }

    if(!params.userAgeMin && !params.userAgeMax) {
        afterSection = '';
    }

    let dateSection = '';

    if(params.startDate) {
        const startDay = dayjs(params.startDate);
        dateSection = dateSection.concat(`from ${startDay.toDate().toLocaleDateString()}`);
    }

    if(params.endDate) {
        const endDay = dayjs(params.endDate);
        dateSection = dateSection.concat(` up to ${endDay.toDate().toLocaleDateString()}`);
    }

    if(!params.includeDate) {
        dateSection = '';
    }

    return `${userSection}${params.chartDefaultTitle}${afterSection} ${dateSection}`;
};