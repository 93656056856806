import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getAllProducts} from "../graphql/queries";
import {ProductOutput} from "../types";

export const useAllProductsFetch = (country: string, language: string) => {
    return useQuery(["all-products", country, language], async () => {
        const result: any = await API.graphql(graphqlOperation(getAllProducts, {
            input: {
                country,
                language
        }}))
        return result!.data!.getAllProducts as ProductOutput[];
    });
}