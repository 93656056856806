import React from 'react';
import {tailwind} from "../tailwind";
import {View} from "react-native";
import PageContainer from "../components/PageContainer";
import Plot from 'react-plotly.js';
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
import create, {SetState} from "zustand";
import dayjs from "dayjs";
import {LoadingOverlay} from "@mantine/core";
import produce from "immer";
import {OfferMapFilters} from "../components/OfferMapFilters";
import {centerCoord, formatOfferMapTrace} from "../utils/map";


const config = {
    mapboxAccessToken: "pk.eyJ1IjoianNpbW9uLWZhdWx0bGluZSIsImEiOiJjazV4ZGRiaTIwMTFsM25xcjcwc3NzaXF6In0.girv7O3wD41pMqlxYHFj0A"
};

type BuyingOfferMapFilter = {
    date: string,
    limit: number,
    country: string,
    unit?: number;
    cooperativeId?: string;
    productId?: string;
    productName: string;
    language: string;
    gender?: string;
    setGender(gender: string): void;
    setLimit(limit: number): void;
    setCountry(country: string): void;
    setDate(date: Date): void;
    setLanguage(language: string): void;
    setProduct(productId: string, productName: string): void;
    setCooperativeId(cooperativeId: string): void;
    minAge?: number;
    maxAge?: number;
    setMinAge(min: number): void;
    setMaxAge(max: number): void;
}

export const useBuyingOfferMapStore = create<BuyingOfferMapFilter>(
    (set: SetState<BuyingOfferMapFilter>) => ({
        date: convertDateToSearchableFormat(dayjs().subtract(1, 'day').toDate()),
        limit: 10,
        country: 'TZ',
        productName: "All",
        language: "en",
        setCountry: (country: string) => set(draft => {
            draft.country = country;
        }),
        setProduct: (productId: string, productName: string) => set(draft => {
            if (productId === 'all') {
                draft.productId = undefined;
                draft.productName = 'All';
                return;
            }

            draft.productId = productId;
            draft.productName = productName;
        }),
        setLanguage: (language: string) => set(draft => {
            draft.language = language;
        }),
        setDate: (date: Date) => set(draft => {
            draft.date = convertDateToSearchableFormat(date);
        }),
        setLimit: (limit: number) => set(produce(draft => {
            draft.limit = limit;
        })),
        setCooperativeId: (cooperativeId: string) => set(draft => {
            if (cooperativeId === 'all') {
                draft.cooperativeId = undefined;
                return;
            }
            draft.cooperativeId = cooperativeId;
        }),
        setGender: (gender: string) => set(draft => {
            if (gender === 'all') {
                draft.gender = undefined;
                return;
            }
            draft.gender = gender;
        }),
        setMinAge: (min => set(draft => {
            if (min === -1) {
                draft.minAge = undefined;
            } else {
                draft.minAge = min;
            }
        })),
        setMaxAge: (max => set(draft => {
            if (max === -1) {
                draft.maxAge = undefined;
            } else {
                draft.maxAge = max;
            }
        }))
    })
);

import * as unitInfo from '../data/units.json';
import {useBuyingOffersByCity} from "../hooks/useBuyingOffersByCity";
import {Layout} from "plotly.js";
import {generateOfferChartTitle} from "../utils/charting";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import {convertDateToSearchableFormat} from "../utils/date";

const englishUnits = unitInfo.en;


export default () => {
    const {
        date,
        country,
        language,
        unit,
        productId,
        cooperativeId,
        productName,
        gender,
        minAge,
        maxAge,
        setProduct,
        setCooperativeId,
        limit,
        setCountry,
        setDate,
        setGender,
        setMinAge,
        setMaxAge
    } = useBuyingOfferMapStore();


    const {
        isFetching,
        data: places
    } = useBuyingOffersByCity({date, limit, country, language, cooperativeId, productId, unit, minAge, maxAge, gender});

    const selectedUnit = unit ? englishUnits.find(({id}) => {
        return unit.toString() === id;
    }) : null;

    const {data} = formatOfferMapTrace(places, true, productName, selectedUnit ? selectedUnit.label : ' Offers');

    const {data: cooperatives} = useCooperativesFetch(country);
    const selectedCooperative = (cooperatives || []).find(({id}) => id === cooperativeId);

    const layout: Partial<Layout> = {
        title: {
            text: generateOfferChartTitle({
                gender,
                userAgeMin: minAge,
                userAgeMax: maxAge,
                cooperativeName: selectedCooperative ? selectedCooperative.name : undefined,
                useSingleDate: true,
                singleDate: date,
                chartDefaultTitle: `${productName} Offers`
            }),
            yanchor: "middle"
        },
        mapbox: {
            style: "streets",
            zoom: 5,
            center: centerCoord
        },
        margin: {
            l: 0,
            b: 0,
            r: 0,
            t: 32
        }
    };

    return (
        <PageContainer>
            <View style={tailwind('flex-col items-start justify-between p-4')}>
                <PageTitle title={"Offer Map"}/>
                <PageDescription
                    description={"A map of the quantity and locations for offers of a given product, on a given day."}/>
            </View>

            <OfferMapFilters country={country}
                             date={date}
                             productId={productId}
                             cooperativeId={cooperativeId}
                             language={language}
                             setGender={setGender}
                             setMinAge={setMinAge}
                             setMaxAge={setMaxAge}
                             setCountry={setCountry}
                             setDate={setDate}
                             setProduct={setProduct}
                             setCooperativeId={setCooperativeId}/>

            <View>
                <LoadingOverlay visible={isFetching}/>
                <Plot
                    // @ts-ignore
                    data={data}
                    layout={layout}
                    config={config}
                    useResizeHandler={true}
                    style={{width: '100%', height: 600}}
                />
            </View>
        </PageContainer>
    );
}