import {Feather} from "@expo/vector-icons";
import {Link} from "react-router-dom";
import {Text, View} from "react-native";
import {getColor, tailwind} from "../tailwind";
import React from "react";
import {useCustomNavLink} from "../hooks/UseCustomNavLink";

// StyledLink Component
export const StyledNavLink = ({
                               to,
                               label,
                               iconName,
                               ...props
                           }: { to: string, label: typeof Feather.name, iconName: string }) => {
    const {isActive} = useCustomNavLink(to);
    return (
        <Link to={to}
              style={{
                  textDecorationLine: 'none',
              }}
              {...props}>
            <View style={tailwind('flex flex-col justify-center items-center p-4')}>
                {React.createElement(Feather, {
                    // @ts-ignore
                    name: iconName,
                    size: 24,
                    color: isActive ? getColor('primary') : '#000'
                })}
                {React.createElement(Text, {
                    style: [
                        tailwind('text-sm font-semibold text-center'),
                        isActive ? tailwind('text-primary') : tailwind('text-gray-600')
                    ]
                }, label)}
            </View>
        </Link>
    )
};