import React, {useEffect, useState} from 'react';
import {Text, View, ViewStyle} from "react-native";
import {tailwind} from "../tailwind";
import {Link, useLocation} from "react-router-dom";
import {useCustomNavLink} from "../hooks/UseCustomNavLink";
import {navConfig, TreeNode} from "../types";

// There is a MainSubNavHeader component that is used to render the header of the sub navigation
const MainSubNavHeader = ({label}: {label: string}) => {
    return (
        <Text style={tailwind("text-xl text-primary font-bold capitalize mb-4")}>
            {label}
        </Text>
    )
}

// There is a SubMenuHeader component that is used to render the header of the sub menu
const SubMenuHeader = ({label, style}: {label: string, style?: ViewStyle}) => {
    return (
        <Text style={[
            tailwind("text-xl text-gray-900 font-bold capitalize"),
            style
        ]}>
            {label}
        </Text>
    )
}

// There is a MainSubNavItem component that is used to render the items of the sub navigation
const MainSubNavItem = ({label, to, variant}: {label: string, to: string, variant: string}) => {
    const {isActive} = useCustomNavLink(to);
    return (
        <Link to={to}
              style={{
                  ...tailwind("flex-row justify-between items-center p-2"),
                  textDecorationLine: 'none',
              }}>
            <Text style={[
                tailwind("text-base text-primary capitalize"),
                isActive && tailwind("font-bold"),
            ]}>
                {label}
            </Text>
        </Link>
    )
}

const SubNav = () => {

    const [nav, setNav] = useState<TreeNode>({});
    const [root, setRoot] = useState<string>("");
    const location = useLocation();

    useEffect(() => {

        if(navConfig) {
            if(location) {
                const path = location.pathname;
                if (path.includes('/home')) {
                    setRoot('home');
                } else if (path.includes('/dashboard')) {
                    setRoot('dashboard');
                }
            }
            const navOptions = navConfig?.children?.filter(({ name }) => name === root)[0];
            if(navOptions) {
                setNav(navOptions);
            }
        }

    }, [navConfig, location, root]);

    return (
        <View style={tailwind('h-full w-full flex-col p-4 border-r border-gray-100')}>
            <MainSubNavHeader label={nav.name}/>
            {nav?.children?.map((con) => {
                return (
                    <View key={con.name} style={tailwind('pl-2')}>
                        <SubMenuHeader label={con.name} />
                        <View style={tailwind('pl-2 mb-6')}>
                            {con?.children?.map(({ name }) => {
                                return (
                                    <MainSubNavItem
                                        key={name}
                                        to={`/app/dashboard/${name}`}
                                        label={name.replaceAll("-", " ")}
                                        variant={"Default"}
                                    />
                                )
                            })}
                        </View>
                    </View>
                )
            })}
        </View>
    )
}

export default SubNav;