import Plot from "react-plotly.js";
import React from "react";
import {useAllProductsFetch} from "../hooks/useAllProductsFetch";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import PageContainer from "../components/PageContainer";
import {View} from "react-native";
import {tailwind} from "../tailwind";
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
import create, {SetState} from "zustand";
import dayjs from "dayjs";
import produce from "immer";
import {Button, LoadingOverlay, Select} from "@mantine/core";
import {DateRangePicker} from "@mantine/dates";
import * as Plotly from "plotly.js";
import {useAvailableInventoryTimeSeries} from "../hooks/useAvailableInventoryTimeSeries";

import * as unitInfo from '../data/units.json';
import {getUserColorByType} from "../utils/UserColoring";
import {CooperativeFilter} from "../components/CooperativeFilter";
import {GenderFilter} from "../components/GenderFilter";
import {ProductFilter} from "../components/ProductFilter";
import {UnitFilter} from "../components/UnitFilter";
import {CountryFilter} from "../components/CountryFilter";
import {AgeFilter} from "../components/AgeFilter";
import {generateOfferChartTitle} from "../utils/charting";
import {convertDateToSearchableFormat} from "../utils/date";

const englishUnits = unitInfo.en;

type AvailableProductsFilter = {
    unit?: number;
    endDate: string,
    startDate: string,
    limit: number,
    country: string,
    cooperativeId?: string;
    productId?: string;
    productName: string;
    language: string;
    gender?: string;
    setUnit: (unit: number) => void;
    setCountry: (country: string) => void;
    setDateRange(dateRange: Date[]): void;
    setLanguage: (language: string) => void;
    setProduct: (productId: string, productName: string) => void;
    setCooperativeId: (cooperativeId: string) => void;
    setGender: (gender: string) => void;
    minAge?: number;
    maxAge?: number;
    setMinAge: (min: number) => void;
    setMaxAge: (max: number) => void;
}

export const useAvailableProductsStore = create<AvailableProductsFilter>(
    (set: SetState<AvailableProductsFilter>) => ({
        endDate: convertDateToSearchableFormat(dayjs().add(2, 'month').toDate()),
        startDate: convertDateToSearchableFormat(dayjs('7/1/2021').toDate()),
        limit: 10,
        country: 'TZ',
        productName: "All",
        language: "en",
        setCountry: (country: string) => set(draft => {
            draft.country = country;
        }),
        setProduct: (productId: string, productName: string) => set(draft => {
            if (productId === 'all') {
                draft.productId = undefined;
                draft.productName = 'All';
                return;
            }

            draft.productId = productId;
            draft.productName = productName;
        }),
        setLanguage: (language: string) => set(draft => {
            draft.language = language;
        }),
        setDateRange: (dateRange: [Date, Date]) => set(draft => {
            draft.startDate = convertDateToSearchableFormat(dateRange[0]);
            draft.endDate = convertDateToSearchableFormat(dateRange[1]);
        }),
        setUnit: (unit: number) => set(produce(draft => {
            if (unit === -1) {
                draft.unit = undefined;
                return;
            }

            draft.unit = unit;
        })),
        setCooperativeId: (cooperativeId: string) => set(draft => {
            if (cooperativeId === 'all') {
                draft.cooperativeId = undefined;
                return;
            }
            draft.cooperativeId = cooperativeId;
        }),
        setGender: (gender: string) => set(draft => {
            if (gender === 'all') {
                draft.gender = undefined;
                return;
            }
            draft.gender = gender;
        }),
        setMinAge: (min => set(draft => {
            if (min === -1) {
                draft.minAge = undefined;
            } else {
                draft.minAge = min;
            }
        })),
        setMaxAge: (max => set(draft => {
            if (max === -1) {
                draft.maxAge = undefined;
            } else {
                draft.maxAge = max;
            }
        }))
    })
);

const AvailableProductsFilters = () => {

    const country = useAvailableProductsStore(state => state.country);
    const endDate = useAvailableProductsStore(state => state.endDate);
    const startDate = useAvailableProductsStore(state => state.startDate);
    const productId = useAvailableProductsStore(state => state.productId);
    const cooperativeId = useAvailableProductsStore(state => state.cooperativeId);

    const setStartDate = useAvailableProductsStore(state => state.setDateRange);
    const setProduct = useAvailableProductsStore(state => state.setProduct);
    const setCountry = useAvailableProductsStore(state => state.setCountry);
    const setCooperativeId = useAvailableProductsStore(state => state.setCooperativeId);

    const {unit, setUnit, gender, setGender, minAge, maxAge, setMinAge, setMaxAge} = useAvailableProductsStore();


    return (
        <View style={tailwind("flex m-2 w-full flex-row justify-end my-6")}>
            <View style={tailwind('flex-1 flex-row mr-6 justify-end')}>
                {/*<CountryFilter setCountry={setCountry} country={country}/>*/}
                <ProductFilter country={country} setProduct={setProduct} productId={productId}/>
                <CooperativeFilter country={country} setCooperativeId={setCooperativeId} cooperativeId={cooperativeId}/>
                <GenderFilter setGender={setGender} gender={gender}/>
                <UnitFilter country={country} setUnit={setUnit} unit={unit}/>
                <AgeFilter setMinAge={setMinAge} setMaxAge={setMaxAge} minAge={minAge} maxAge={maxAge}/>
                <DateRangePicker
                    label="Date range"
                    style={tailwind('flex-1 mr-2')}
                    placeholder="Pick dates range"
                    value={[new Date(startDate), new Date(endDate)]}
                    onChange={(value: [Date, Date]) => {
                        //if second date is null do not update the date range
                        if (value[1]) {
                            setStartDate(value);
                        }
                    }}
                />
            </View>
        </View>
    )
}

export default () => {

    const startDate = useAvailableProductsStore(state => state.startDate);
    const endDate = useAvailableProductsStore(state => state.endDate);
    const limit = useAvailableProductsStore(state => state.limit);
    const unit = useAvailableProductsStore(state => state.unit);
    const gender = useAvailableProductsStore(state => state.gender);
    const country = useAvailableProductsStore(state => state.country);
    const cooperativeId = useAvailableProductsStore(state => state.cooperativeId);
    const productId = useAvailableProductsStore(state => state.productId);
    const productName = useAvailableProductsStore(state => state.productName);
    const language = useAvailableProductsStore(state => state.language);
    const minAge = useAvailableProductsStore(state => state.minAge);
    const maxAge = useAvailableProductsStore(state => state.maxAge);

    const {data: cooperatives} = useCooperativesFetch(country);

    const {
        data,
        isFetching
    } = useAvailableInventoryTimeSeries({
        startDate,
        endDate,
        limit,
        country,
        language,
        cooperativeId,
        productId,
        unitId: unit,
        gender,
        minAge,
        maxAge
    });

    const today = dayjs();

    const reportedData = data?.filter((item) => {
        const itemDate = dayjs(item.timeStampAsString);
        return itemDate.isBefore(today);
    });

    const projectedData = data?.filter((item) => {
        const itemDate = dayjs(item.timeStampAsString);
        return !itemDate.isBefore(today);
    });

    const plotlyData: Plotly.Data[] = [
        {
            x: reportedData?.map(item => item.timeStampAsString) ?? [],
            y: reportedData?.map(item => item.qty) ?? [],
            type: 'bar',
            name: `${productName} Posts`,
            marker: {
                color: getUserColorByType('producer')
            }
        },
        {
            x: projectedData?.map(item => item.timeStampAsString) ?? [],
            y: projectedData?.map(item => item.qty) ?? [],
            type: 'bar',
            name: `${productName} Posts`,
            marker: {
                color: 'rgb(146, 115, 162)'
            }
        }
    ];

    const selectedUnit = unit ? englishUnits.find(({id}) => {
        return unit.toString() === id;
    }) : null;

    const selectedCooperative = (cooperatives || []).find(({id}) => id === cooperativeId);

    const chartTitle = generateOfferChartTitle({
        gender,
        userAgeMax: maxAge,
        userAgeMin: minAge,
        startDate,
        endDate,
        cooperativeName: selectedCooperative ? selectedCooperative.name : undefined,
        chartDefaultTitle: `${productName} Posts`
    });

    const layout: any = {
        title: chartTitle,
        xaxis: {
            rangemode: 'tozero',
            autorange: false,
            title: 'Date',
            range: [startDate, endDate]
        },
        yaxis: {
            rangemode: 'nonnegative',
            autorange: true,
            title: selectedUnit ? selectedUnit.label : 'Posts'
        },
        showlegend: false
    };

    const config: any = {
        toImageButtonOptions: {
            format: 'png', // one of png, svg, jpeg, webp
            filename: 'custom_image',
            height: 500,
            width: 700,
            scale: 5 // Multiply title/legend/axis/canvas sizes by this factor
        }
    };



    return (
        <PageContainer>
            <View style={tailwind('flex-col items-start justify-between p-4')}>
                <PageTitle title={"Available Products"}/>
                <PageDescription
                    description={"This page shows the available inventory of a selected product over time."}/>
            </View>

            <AvailableProductsFilters/>

            <View>
                <LoadingOverlay visible={isFetching}/>
                <Plot
                    data={plotlyData}
                    layout={layout}
                    useResizeHandler={true}
                    config={config}
                    style={{width: '100%', height: '100%'}}
                />
            </View>
        </PageContainer>
    )
}


