import React, {useCallback, useState} from 'react';
import {Text, RangeSlider, InputWrapper} from '@mantine/core';
import {View} from "react-native";
import tailwind from "tailwind-rn";
import {debounce} from 'lodash';

export interface AgeFilterProps {
    minAge?: number;
    maxAge?: number;
    setMinAge: (minAge: number) => void;
    setMaxAge: (maxAge: number) => void;
}

export const AgeFilter = ({minAge, maxAge, setMaxAge, setMinAge}: AgeFilterProps) => {
    const [currentMin, setCurrentMin] = useState<number>(minAge || 18);
    const [currentMax, setCurrentMax] = useState<number>(maxAge || 100);

    const changeHandler = debounce(([min, max]: [number, number]) => {
        setMinAge(min);
        setMaxAge(max);
    }, 500);


    const updateValues = useCallback(([min, max]) => {
        changeHandler([min, max]);
        setCurrentMax(max);
        setCurrentMin(min);
    }, []);

    return (
        <View style={tailwind('w-48 h-12 px-4')}>
            <InputWrapper label={"User Age"} labelProps={{
                style: {
                    transform: 'translateX(-8px)'
                }
            }}>
            <RangeSlider onChange={updateValues}
                         minRange={1}
                         min={18}
                         max={100}
                         marks={[
                             {value: 18, label: '18'},
                             {value: 100, label: '100'},
                         ]}
                         size="md"
                         value={[currentMin, currentMax]}/>
            </InputWrapper>
        </View>
    );
};