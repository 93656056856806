//Component to display the title for page details
import {Text, View} from "react-native";
import {tailwind} from "../tailwind";
import React from "react";

export const PageTitle = ({title}: { title: string }) => {
    return (
        <View style={tailwind('flex-row items-center justify-between py-4')}>
            <Text style={tailwind('text-4xl font-bold')}>{title}</Text>
        </View>
    )
};