import {View} from "react-native";
import {tailwind} from "../tailwind";
import {Select} from "@mantine/core";
import {DateRangePicker} from "@mantine/dates";
import React from "react";
import {CooperativeFilter} from "./CooperativeFilter";
import {GenderFilter} from "./GenderFilter";
import {AgeFilter} from "./AgeFilter";
import {CountryFilter} from "./CountryFilter";
import {ProductFilter} from "./ProductFilter";

export type UserFiltersProps = {
    country: string,
    endDate: string,
    startDate: string,
    productId?: string,
    cooperativeId?: string,
    gender?: string,
    minAge?: number;
    maxAge?: number;
    setCountry: (country: string) => void;
    setDateRange(dateRange: Date[]): void;
    setCooperativeId(cooperativeId: string): void;
    setProduct: (productId: string, productName: string) => void;
    setGender(gender: string): void;
    setMinAge(min: number): void;
    setMaxAge(max: number): void;
}

export const UserFilters = (props: UserFiltersProps) => {
    const {
        country,
        startDate,
        endDate,
        cooperativeId,
        gender,
        minAge,
        maxAge,
        productId,
        setCountry,
        setDateRange,
        setCooperativeId,
        setGender,
        setMinAge,
        setMaxAge,
        setProduct
    } = props;

    return (
        <View style={tailwind("flex m-2 w-full justify-end my-6")}>
            <View style={tailwind('flex-1 flex-row mr-6 justify-end')}>
                {/*<CountryFilter setCountry={setCountry} country={country}/>*/}
                <CooperativeFilter country={country} setCooperativeId={setCooperativeId} cooperativeId={cooperativeId}/>
                <GenderFilter setGender={setGender} gender={gender}/>
                <AgeFilter setMinAge={setMinAge} setMaxAge={setMaxAge} minAge={minAge} maxAge={maxAge}/>
                <ProductFilter country={country} setProduct={setProduct} productId={productId}/>
                <DateRangePicker
                    label="Date range"
                    style={tailwind('flex-1 mr-2')}
                    placeholder="Pick dates range"
                    value={[new Date(startDate), new Date(endDate)]}
                    onChange={(value: [Date, Date]) => {
                        //if second date is null do not update the date range
                        if (value[1]) {
                            setDateRange(value);
                        }
                    }}
                />
            </View>
        </View>
    )
}