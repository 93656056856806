import React from 'react';
import {Select} from "@mantine/core";
import {tailwind} from "../tailwind";

export interface GenderFilterProps {
    gender?:string;
    setGender(gender:string):void;
}

export const GenderFilter = ({gender, setGender}:GenderFilterProps) => {
    const genderOptions = [
        {value: 'all', label: 'All'},
        {value: 'male', label: 'Male'},
        {value: 'female', label: 'Female'},
        {value: 'other', label: 'Other'},
        {value: 'declined', label: 'Declined'}
    ];

    return (<Select
        data={genderOptions}
        label={"Select Gender"}
        style={tailwind('flex-1 mr-2')}
        placeholder="Pick one"
        value={gender || 'all'}
        onChange={(value: string) => setGender(value)}
    />)
};