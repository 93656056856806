import Constants from 'expo-constants';


const awsconfig = {
    "aws_appsync_graphqlEndpoint": `${Constants.manifest.extra.REACT_APP_API_ENDPOINT}`,
    "aws_appsync_region": `${Constants.manifest.extra.REACT_APP_REGION}`,
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": `${Constants.manifest.extra.REACT_APP_REGION}`,
    "aws_user_pools_id":  `${Constants.manifest.extra.REACT_APP_USER_POOL_ID}`,
    "aws_user_pools_web_client_id": `${Constants.manifest.extra.REACT_APP_USER_POOL_WEB_CLIENT_ID}`,
    "aws_mandatory_sign_in": "enable" // (optional) - Users are not allowed to get the aws credentials unless they are signed in
}

// console.log("Config : ", awsconfig)


export default awsconfig;