import {OfferAggregate} from "../types";
import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getBuyingOffersByProduct} from "../graphql/queries";

export interface UseBuyingOfferAggregateFetchParams {
    startDate: string;
    endDate: string;
    limit: number;
    availableCountries: string[];
    language: string;
    gender?: string;
    cooperativeId?: string;
    minAge?: number;
    maxAge?: number;
}

export const useBuyingOfferAggregateFetch = (params: UseBuyingOfferAggregateFetchParams) => {
    const {
        startDate,
        endDate,
        limit,
        availableCountries,
        language,
        cooperativeId,
        gender,
        minAge,
        maxAge
    } = params;

    return useQuery(['buying-offer-query', startDate, endDate, limit, availableCountries, language, cooperativeId, gender, minAge, maxAge], async () => {
        const result: any = await API.graphql(graphqlOperation(getBuyingOffersByProduct, {
            input: {
                startDate,
                endDate,
                availableCountries,
                limit,
                language,
                minAge,
                maxAge,
                cooperativeId,
                gender
            }
        }));

        return result!.data!.getBuyingOffersByProduct as OfferAggregate[];
    });
}