import Plot from "react-plotly.js";
import React from "react";
import {useAllProductsFetch} from "../hooks/useAllProductsFetch";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import PageContainer from "../components/PageContainer";
import {View} from "react-native";
import {tailwind} from "../tailwind";
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
import create, {SetState} from "zustand";
import dayjs from "dayjs";
import produce from "immer";
import {LoadingOverlay, NumberInput, Select} from "@mantine/core";
import {DateRangePicker} from "@mantine/dates";
import * as Plotly from "plotly.js";
import {useAggregateBuyingOffersTimeSeriesFetch} from "../hooks/useAggregateBuyingOffersTimeSeriesFetch";

import * as unitInfo from '../data/units.json';
import {getUserColorByType} from "../utils/UserColoring";
import {ProductFilter} from "../components/ProductFilter";
import {CooperativeFilter} from "../components/CooperativeFilter";
import {GenderFilter} from "../components/GenderFilter";
import {AgeFilter} from "../components/AgeFilter";
import {CountryFilter} from "../components/CountryFilter";
import {generateOfferChartTitle} from "../utils/charting";
import {convertDateToSearchableFormat} from "../utils/date";

const englishUnits = unitInfo.en;


type OfferHistoryFilter = {
    endDate: string,
    startDate: string,
    limit: number,
    unit?: number,
    country: string,
    cooperativeId?: string;
    productId?: string;
    productName: string;
    language: string;
    gender?: string;
    minAge?: number;
    maxAge?: number;
    setLimit: (limit: number) => void;
    setCountry: (country: string) => void;
    setDateRange(dateRange: Date[]): void;
    setLanguage: (language: string) => void;
    setProduct: (productId: string, productName: string) => void;
    setCooperativeId: (cooperativeId: string) => void;
    setMinAge(min: number): void;
    setMaxAge(max: number): void;
    setGender(gender: string): void;
}

export const useOfferHistoryStore = create<OfferHistoryFilter>(
    (set: SetState<OfferHistoryFilter>) => ({
        endDate: convertDateToSearchableFormat(dayjs().toDate()),
        startDate: convertDateToSearchableFormat(dayjs('8/1/2021').subtract(30, 'day').toDate()),
        limit: 10,
        country: 'TZ',
        productName: "All",
        language: "en",
        setCountry: (country: string) => set(draft => {
            draft.country = country;
        }),
        setProduct: (productId: string, productName: string) => set(draft => {
            if(productId === 'all') {
                draft.productId = undefined;
                draft.productName = 'All';
                return;
            }

            draft.productId = productId;
            draft.productName = productName;
        }),
        setLanguage: (language: string) => set(draft => {
            draft.language = language;
        }),
        setDateRange: (dateRange: [Date, Date]) => set(draft => {
            draft.startDate = convertDateToSearchableFormat(dateRange[0]);
            draft.endDate = convertDateToSearchableFormat(dateRange[1]);
        }),
        setLimit: (limit: number) => set(produce(draft => {
            draft.limit = limit;
        })),
        setCooperativeId: (cooperativeId: string) => set(draft => {
            if(cooperativeId === 'all') {
                draft.cooperativeId = undefined;
                return;
            }

            draft.cooperativeId = cooperativeId;
        }),
        setGender: (gender: string) => set(draft => {
            if (gender === 'all') {
                draft.gender = undefined;
                return;
            }
            draft.gender = gender;
        }),
        setMinAge: (min => set(draft => {
            if (min === -1) {
                draft.minAge = undefined;
            } else {
                draft.minAge = min;
            }
        })),
        setMaxAge: (max => set(draft => {
            if (max === -1) {
                draft.maxAge = undefined;
            } else {
                draft.maxAge = max;
            }
        }))
    })
);

const OfferHistoryFilters = () => {
    const country = useOfferHistoryStore(state => state.country);
    const endDate = useOfferHistoryStore(state => state.endDate);
    const startDate = useOfferHistoryStore(state => state.startDate);
    const productId = useOfferHistoryStore(state => state.productId);
    const cooperativeId = useOfferHistoryStore(state => state.cooperativeId);

    const setStartDate = useOfferHistoryStore(state => state.setDateRange);
    const setProduct = useOfferHistoryStore(state => state.setProduct);
    const setCountry = useOfferHistoryStore(state => state.setCountry);
    const setCooperativeId = useOfferHistoryStore(state => state.setCooperativeId);


    const {
        gender,
        maxAge,
        minAge,
        setGender,
        setMinAge,
        setMaxAge
    } = useOfferHistoryStore();

    return (
        <View style={tailwind("flex m-2 w-full flex-row justify-end my-6")}>
            <View style={tailwind('flex-1 flex-row mr-6 justify-end')}>
                {/*<CountryFilter setCountry={setCountry} country={country}/>*/}
                <ProductFilter country={country} setProduct={setProduct} productId={productId}/>
                <CooperativeFilter country={country} setCooperativeId={setCooperativeId} cooperativeId={cooperativeId}/>
                <GenderFilter setGender={setGender} gender={gender}/>
                <AgeFilter setMinAge={setMinAge} setMaxAge={setMaxAge} minAge={minAge} maxAge={maxAge}/>
                <DateRangePicker
                    label="Date range"
                    style={tailwind('flex-1 mr-2')}
                    placeholder="Pick dates range"
                    value={[new Date(startDate), new Date(endDate)]}
                    onChange={(value: [Date, Date]) => {
                        //if second date is null do not update the date range
                        if (value[1]) {
                            setStartDate(value);
                        }
                    }}
                />
            </View>
        </View>
    )
}

export default () => {

    const startDate = useOfferHistoryStore(state => state.startDate);
    const endDate = useOfferHistoryStore(state => state.endDate);
    const limit = useOfferHistoryStore(state => state.limit);
    const unit = useOfferHistoryStore(state => state.unit);
    const country = useOfferHistoryStore(state => state.country);
    const cooperativeId = useOfferHistoryStore(state => state.cooperativeId);
    const productId = useOfferHistoryStore(state => state.productId);
    const productName = useOfferHistoryStore(state => state.productName);
    const language = useOfferHistoryStore(state => state.language);
    const {
        minAge,
        maxAge,
        gender
    } = useOfferHistoryStore();

    const {data, isFetching} = useAggregateBuyingOffersTimeSeriesFetch({
        startDate,
        endDate,
        limit,
        country,
        language,
        cooperativeId,
        productId,
        unitId: unit,
        minAge,
        maxAge,
        gender
    });
    
    const plotlyData: Plotly.Data[] = [
        {
            x: data?.map(item => item.timeStampAsString) ?? [],
            y: data?.map(item => item.qty) ?? [],
            type: 'scatter',
            mode: 'lines+markers',
            name: `${productName} Offers`,
            line: {
                color: getUserColorByType('buyer')
            },
        }
    ];

    const selectedUnit = unit ? englishUnits.find(({id}) => {
        return unit.toString() === id;
    }) : null;

    const {data: cooperatives} = useCooperativesFetch(country);
    const selectedCooperative = (cooperatives || []).find(({id}) => id === cooperativeId);

    const chartTitle = generateOfferChartTitle({
        gender,
        userAgeMax: maxAge,
        userAgeMin: minAge,
        startDate,
        endDate,
        cooperativeName: selectedCooperative ? selectedCooperative.name : undefined,
        chartDefaultTitle: `${productName} Offers`
    });

    const layout:any = {
        title: chartTitle,
        xaxis: {
            rangemode: 'tozero',
            autorange: false,
            title: 'Date',
            range: [startDate, endDate]
        },
        yaxis: {
            rangemode: 'nonnegative',
            autorange: true,
            title: selectedUnit ? selectedUnit.label : 'Offers'
        }
    };

    return (
        <PageContainer>
            <View style={tailwind('flex-col items-start justify-between p-4')}>
                <PageTitle title={"Offer History"}/>
                <PageDescription description={"This page shows the volume of Offers for a selected product in Kg."}/>
            </View>

            <OfferHistoryFilters/>
            <View>
                <LoadingOverlay visible={isFetching}/>
                <Plot
                    data={plotlyData}
                    layout={layout}
                    useResizeHandler={true}
                    style={{width: '100%', height: '100%'}}
                />
            </View>
        </PageContainer>
    )
}


