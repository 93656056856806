import {OfferAggregate} from "../types";
import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getSellingOffersByProduct} from "../graphql/queries";

export interface UseSellingOfferAggregateFetchParams {
    startDate: string;
    endDate: string;
    limit: number;
    availableCountries: string[];
    language: string;
    gender?: string;
    cooperativeId?: string;
    minAge?: number;
    maxAge?: number;
}

export const useSellingOfferAggregateFetch = (params: UseSellingOfferAggregateFetchParams) => {
    const {
        startDate,
        endDate,
        limit,
        availableCountries,
        language,
        cooperativeId,
        gender,
        minAge,
        maxAge
    } = params;

    return useQuery(['selling-offer-query', startDate, endDate, limit, availableCountries, language, cooperativeId, gender, minAge, maxAge], async () => {
        const result: any = await API.graphql(graphqlOperation(getSellingOffersByProduct, {
            input: {
                startDate,
                endDate,
                availableCountries,
                limit,
                language,
                minAge,
                maxAge,
                cooperativeId,
                gender
            }
        }));
        // console.log("getSellingOffersByProduct : ", result);
        return result!.data!.getSellingOffersByProduct as OfferAggregate[];
    });
}