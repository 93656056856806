export enum UserColoring {
    Buyer = 'buyer',
    Producer = 'producer'
}

export const getUserColorByType = (type:string):string => {
    if(type === UserColoring.Buyer) {
        return '#EF7E56';
    }

    if(type === UserColoring.Producer) {
        return '#7CAE75';
    }

    //Default to producer if unknown
    return '#7CAE75';
}