import React, {useCallback, useState} from "react";
import {tailwind} from "../tailwind";
import {View, ViewStyle} from "react-native";
import {UseQueryResult} from "react-query";
import {
    ActionIcon,
    Center,
    Divider,
    LoadingOverlay,
    Paper,
    Table,
    Text,
    Drawer
} from '@mantine/core';
import {GearIcon} from "@radix-ui/react-icons";
import {useAggregateBuyingOffersByUnitTypeFetch} from "../hooks/useAggregateBuyingOffersByUnitTypeFetch";
import {useSellingOfferAggregateFetch} from "../hooks/useSellingOfferAggregateFetch";
import {useBuyingOfferAggregateFetch} from "../hooks/useBuyingOfferAggregateFetch";
import {getAggregateBuyersByBuyingOffersFetch} from "../hooks/useAggregateBuyersByBuyingOffers";
import {useAggregateProducersByBuyingOffersFetch} from "../hooks/useAggregateProducersByBuyingOffersFetch";
import {useAggregateBuyerByOrdersFetch} from "../hooks/useAggregateBuyerByOrdersFetch";
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
//@ts-ignore
import dashboardImage from '../../assets/dashboard-illustration.png';
import {DashboardGraph, DashboardGraphType} from "../components/DashboardGraph";
import {useNavigate} from "react-router-dom";
import {useGlobalFilters} from "../hooks/useGlobalFilters";
import {GlobalFilters} from "../components/GlobalFilters";

const ListWrapper = ({children}: { children: React.ReactNode }) => {
    return (
        <Paper radius={"xl"}
               shadow={"sm"}
               style={{
                   ...tailwind("flex flex-col bg-white p-4 m-4 rounded-lg h-64 w-64"),
                   position: "relative",
                   overflow: "hidden"
               }}>
            {children}
        </Paper>
    )
}

const TopListHeader = ({title}: { title: string }) => {
    return (
        <>
            <View style={tailwind("flex flex-row items-center justify-between py-1")}>
                <Text size={'lg'} weight={"bold"} title={title}>{title}</Text>
            </View>
            <Divider/>
        </>
    );
};

type ListData = {
    result: UseQueryResult<any[]>,
    rightLabel: string
}

const AggList = ({result, rightLabel}: ListData) => {

    if (result.isFetching) {
        return <LoadingOverlay visible={result.isFetching}/>
    }

    if (result && result.data && result.data.length > 0) {
        return (
            <Table>
                <tbody>
                {result.data.slice(0, 5).map((obj, index) => {
                    return (
                        <tr key={index}>
                            <td>{obj?.[rightLabel]}</td>
                            <td>{obj.count}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        )
    }

    if (result.isError) {
        return (
            <Center style={tailwind('h-full')}>
                <Text color={"red"}>Error</Text>
            </Center>
        )
    }

    return (
        <>
            {result && result.data && result.data.length === 0 && (
                <Center style={tailwind('h-full')}>
                    <Text>No data</Text>
                </Center>
            )}
        </>
    );
}

type ListProps = {
    title: string
}

// Product List
const TopSellingOffersList = ({title}: ListProps) => {
    const {startDate, endDate, country, language} = useGlobalFilters();

    const result = useSellingOfferAggregateFetch({
        startDate,
        endDate,
        limit: 250,
        availableCountries: [country],
        language
    });

    return (
        <ListWrapper>
            <TopListHeader title={title}/>
            <AggList result={result} rightLabel={"productName"}/>
        </ListWrapper>
    );
};

const TopBuyingOfferList = ({title}: ListProps) => {
    const {startDate, endDate, country, language} = useGlobalFilters();
    const result = useBuyingOfferAggregateFetch({
        startDate,
        endDate,
        limit: 250,
        availableCountries: [country],
        language
    });
    return (
        <ListWrapper>
            <TopListHeader title={title}/>
            <AggList result={result} rightLabel={"productName"}/>
        </ListWrapper>
    );
};

const TopProducerSellingOfferList = ({title}: ListProps) => {
    const {startDate, endDate, country} = useGlobalFilters();

    const result = useAggregateProducersByBuyingOffersFetch(startDate, endDate, 250, country);
    return (
        <ListWrapper>
            <TopListHeader title={title}/>
            <AggList result={result} rightLabel={"userName"}/>
        </ListWrapper>
    );
};

const TopBuyersList = ({title}: ListProps) => {
    const {startDate, endDate, country} = useGlobalFilters();
    console.log('Top buyers ', startDate, endDate, country)
    const result = getAggregateBuyersByBuyingOffersFetch(startDate, endDate, 250, country);
    return (
        <ListWrapper>
            <TopListHeader title={title}/>
            <AggList result={result} rightLabel={"userName"}/>
        </ListWrapper>
    );
};

const TopBuyersByOrderList = ({title}: ListProps) => {
    const {startDate, endDate, country} = useGlobalFilters();
    const result = useAggregateBuyerByOrdersFetch(startDate, endDate, 250, country);
    return (
        <ListWrapper>
            <TopListHeader title={title}/>
            <AggList result={result} rightLabel={"userName"}/>
        </ListWrapper>
    );
};

const TopOffersByUnitList = ({title}: ListProps) => {
    const {startDate, endDate, country, language} = useGlobalFilters();
    const result = useAggregateBuyingOffersByUnitTypeFetch(startDate, endDate, 250, country, language);
    return (
        <ListWrapper>
            <TopListHeader title={title}/>
            <AggList result={result} rightLabel={"unitName"}/>
        </ListWrapper>
    );
};

const HEADER_HEIGHT = 40;

const Home = () => {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);

    const closeCallback = useCallback(() => {
        setOpened(false);
    }, []);

    const openCallback = useCallback(() => {
        setOpened(true);
    }, []);
    // @ts-ignore
    return (
        <View style={tailwind('overflow-scroll h-full')}>
            <View style={[tailwind('w-full'), {height: `calc(100vh - ${HEADER_HEIGHT}px)`}]}>
                <View style={tailwind('flex-col items-start justify-between p-4')}>
                    <View style={tailwind('flex flex-row justify-start items-center')}>
                        <PageTitle title={"Welcome to Agromovil"}/>
                        <ActionIcon onClick={openCallback} style={tailwind('ml-4')} size="md">
                            <GearIcon height="100%" width="100%"/>
                        </ActionIcon>
                    </View>

                    <PageDescription
                        description={"Welcome to your analytics tool dashboard. Here you will find all the information you are looking for."}/>
                </View>
                <View style={[tailwind('w-full'), {
                    flex: 2,
                    backgroundImage: `url(${dashboardImage})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                } as ViewStyle]}>
                </View>
                <View style={[tailwind('flex-row flex-nowrap items-center justify-center overflow-scroll'), {flex: 1}]}>
                    <DashboardGraph type={DashboardGraphType.ProducerGrowth}
                                    onPress={() => navigate('/app/dashboard/user-growth')}/>
                    <DashboardGraph type={DashboardGraphType.BuyerGrowth}
                                    onPress={() => navigate('/app/dashboard/user-growth')}/>
                    <DashboardGraph type={DashboardGraphType.AvailablePosts}
                                    onPress={() => navigate('/app/dashboard/available-products')}/>
                    <DashboardGraph type={DashboardGraphType.SubmittedOffers}
                                    onPress={() => navigate('/app/dashboard/offer-history')}/>
                </View>
            </View>
            <View style={tailwind("flex-row flex-wrap items-center justify-center")}>
                <TopSellingOffersList title={"Top Selling Offers"}/>
                <TopBuyingOfferList title={"Top Buying Offers"}/>
                <TopProducerSellingOfferList title={"Top Producer Offers"}/>
                <TopBuyersList title={"Top Buyers"}/>
                <TopBuyersByOrderList title={"Top Buyers By Order"}/>
                <TopOffersByUnitList title={"Top Offers By Unit"}/>
            </View>
            <Drawer opened={opened} onClose={closeCallback} padding={"sm"} hideCloseButton>
                <GlobalFilters onBack={closeCallback}/>
            </Drawer>
        </View>
    );
};

export default Home;
