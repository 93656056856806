import {CognitoUser} from '@aws-amplify/auth'
import create from "zustand";
import {Auth} from "aws-amplify";

export enum AuthChallenge {
    ForgotPassword = 'FORGOT_PASSWORD',
    NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
    ResetPassword = 'RESET_PASSWORD'
}

interface UserLoginStore {
    user: CognitoUser | null;
    email: string;
    challenge?: AuthChallenge;
    loading: boolean;
    initialLoading: boolean;
    error: string | null;
    success: boolean;
    setEmail: (email: string) => void;
    setChallenge: (challenge?: AuthChallenge) => void;
    setLoading: (loading: boolean) => void;
    setInitialLoading: (loading: boolean) => void;
    setError: (error: string | null) => void;
    setSuccess: (success: boolean) => void;
    setUser: (user: CognitoUser | null) => void;
}

const useUserStore = create<UserLoginStore>((set) => ({
    user: null,
    email: '',
    loading: false,
    initialLoading: true,
    error: null,
    success: false,
    challenge: undefined,
    setEmail: (email: string) => set((state) => ({email})),
    setChallenge: (challenge?: AuthChallenge) => set((state) => ({challenge})),
    setLoading: (loading: boolean) => set((state) => ({loading})),
    setInitialLoading: (initialLoading: boolean) => set((state) => ({initialLoading})),
    setError: (error: string | null) => set((state) => ({error})),
    setSuccess: (success: boolean) => set((state) => ({success})),
    setUser: (user: CognitoUser | null) => set((state) => ({user})),
}));


const useUser = () => {
    const {
        user,
        email,
        challenge,
        error,
        success,
        loading,
        initialLoading,
        setEmail,
        setLoading,
        setInitialLoading,
        setError,
        setSuccess,
        setUser,
        setChallenge
    } = useUserStore();

    return {
        user,
        challenge,
        error,
        success,
        loading,
        initialLoading,
        email,
        initialLoad: () => {
            (async () => {
                setInitialLoading(true);
                try {
                    const user = await Auth.currentAuthenticatedUser();
                    if (user) {
                        setUser(user);
                        setError('');
                        setSuccess(true);
                        setInitialLoading(false);
                        return;
                    }
                } catch (e) {
                    setInitialLoading(false);
                }
            })();
        },
        logout: () => {
            (async () => {
                setLoading(false);
                await Auth.signOut();
                setUser(null);
                setLoading(false);
                setLoading(false);
                setSuccess(false);
                setError(null);
            })();

        },
        login: (email: string, password: string) => {
            return (async () => {
                setEmail(email);
                setLoading(true);
                setError('');
                setSuccess(false);

                console.table({email, password});

                try {
                    const user = await Auth.signIn(email, password);
                    setUser(user);
                    console.log('User is', user);
                    if (user.challengeName) {
                        setChallenge(user.challengeName);
                        setSuccess(false);
                        setLoading(false);
                        throw new Error('');
                    }
                } catch (e) {
                    console.log('error signing in', e.code);
                    setError(e.message);
                    setSuccess(false);
                    setLoading(false);
                    throw e;
                }

                setSuccess(true);
                setLoading(false);
                setError(null);
            })();
        },
        confirmNewPassword: (password: string, confirmPassword: string) => {
            return (async () => {
                setLoading(true);
                setError('');

                if(password !== confirmPassword) {
                    setError('Passwords must match.');
                    setLoading(false);
                    setSuccess(false);
                    throw new Error('NEW_PASSWORD_MATCH');
                }

                try {
                    const updatedUser = await Auth.completeNewPassword(user, password);
                    setUser(updatedUser);
                    setLoading(false);
                    setSuccess(true);
                    setChallenge(undefined);
                } catch (e) {
                    console.log('Error is', e.message);
                    setError(e.message);
                    setLoading(false);
                    setSuccess(false);
                    throw e;
                }
            })();
        },
        forgotPassword: () => {
            setChallenge(AuthChallenge.ForgotPassword);
        },
        cancelForgotPassword: () => {
            setChallenge(undefined);
        },
        requestCode: (emailAddress:string) => {
            return (async () => {
                setError('');
                setEmail(emailAddress);
                setLoading(true);
                try {
                    await Auth.forgotPassword(emailAddress);
                } catch(e) {
                    setError(e.message);
                    setLoading(false);
                    return;
                }

                setLoading(false);
                setChallenge(AuthChallenge.ResetPassword);
            })();
        },
        resetPassword: (code: string, newPassword:string, confirmPassword:string) => {
            return (async () => {
                setLoading(true);
                setError('');

                if(newPassword !== confirmPassword) {
                    setError('Passwords must match.');
                    setLoading(false);
                    setSuccess(false);
                    throw new Error('NEW_PASSWORD_MATCH');
                }

                try {
                    await Auth.forgotPasswordSubmit(email, code, newPassword);
                    setLoading(false);
                    setSuccess(true);
                    alert("Your password is updated please log in.")
                    setChallenge(undefined);
                } catch (e) {
                    setError(e.message);
                    setLoading(false);
                    setSuccess(false);
                    throw e;
                }
            })();
        }
    };
};

export default useUser;