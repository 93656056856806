// SellingOfferPieChart: a component that renders a plotly chart with the data from getAggregateOffersData path json filimport {useSellingOfferAggregateFetch} from "../hooks/useSellingOfferAggregateFetch";
import loadingAnimation from "../animations/loading.json";
import Lottie from "react-lottie";
import Plot from "react-plotly.js";
import React, {useEffect} from "react";
import {getPageDetails, OfferAggregate, PageDetail} from "../types";
import {Layout, PieData} from "plotly.js";
import {useSellingOfferAggregateFetch} from "../hooks/useSellingOfferAggregateFetch";
import PageContainer from "../components/PageContainer";
import {View} from "react-native";
import {tailwind} from "../tailwind";
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
import create, {SetState} from "zustand";
import dayjs from "dayjs";
import produce from "immer";
import {Button, LoadingOverlay, NumberInput, Select} from "@mantine/core";
import {DateRangePicker} from "@mantine/dates";
import {CountryFilter} from "../components/CountryFilter";
import {countBy} from "lodash";
import {GenderFilter} from "../components/GenderFilter";
import {CooperativeFilter} from "../components/CooperativeFilter";
import {AgeFilter} from "../components/AgeFilter";
import {generateOfferChartTitle} from "../utils/charting";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import {convertDateToSearchableFormat} from "../utils/date";

/**
 * extractAggregateOffersData: a function that extracts the data from getAggregateOffersData path json file into a format that can be used by the plotly chart
 * returns an array of PieData
 * @param input OfferAggregate[]
 */
const extractAggregateOffersData = (input: OfferAggregate[]): PieData => {
    // @ts-ignore
    return {
        labels: input.map(({productName}) => productName),
        values: input.map(({count}) => count),
        type: 'pie',
        textinfo: 'label+percent',
        textposition: 'outside',
        hoverinfo: 'label+percent+name',
        automargin: true,
    };
};

type SellingOfferFilter = {
    availableCountries: string[],
    endDate: string,
    startDate: string,
    language: string,
    limit: number,
    minAge?: number;
    maxAge?: number;
    cooperativeId?: string;
    gender?: string;
    setLanguage: (language: string) => void;
    setLimit: (limit: number) => void;
    setAvailableCountries: (availableCountries: string[]) => void;
    setDateRange(dateRange: Date[]): void;
    setMinAge(min: number): void;
    setMaxAge(max: number): void;
    setCooperativeId: (cooperativeId: string) => void;
    setGender: (gender: string) => void;
}

export const useSellingOfferStore = create<SellingOfferFilter>(
    (set: SetState<SellingOfferFilter>) => ({
        availableCountries: ['TZ'],
        endDate: convertDateToSearchableFormat(dayjs().toDate()),
        startDate: convertDateToSearchableFormat(dayjs().subtract(2, 'month').toDate()),
        language: 'en',
        limit: 500,
        setAvailableCountries: (availableCountries: string[]) => set(draft => {
            draft.availableCountries = availableCountries;
        }),
        setDateRange: (dateRange: [Date, Date]) => set(draft => {
            draft.startDate = convertDateToSearchableFormat(dateRange[0]);
            draft.endDate = convertDateToSearchableFormat(dateRange[1]);
        }),
        setLanguage: (language: string) => set(produce(draft => {
            draft.language = language;
        })),
        setLimit: (limit: number) => set(produce(draft => {
            draft.limit = limit;
        })),
        setCooperativeId: (cooperativeId: string) => set(draft => {
            if (cooperativeId === 'all') {
                draft.cooperativeId = undefined;
                return;
            }
            draft.cooperativeId = cooperativeId;
        }),
        setGender: (gender: string) => set(draft => {
            if (gender === 'all') {
                draft.gender = undefined;
                return;
            }
            draft.gender = gender;
        }),
        setMinAge: (min => set(draft => {
            if (min === -1) {
                draft.minAge = undefined;
            } else {
                draft.minAge = min;
            }
        })),
        setMaxAge: (max => set(draft => {
            if (max === -1) {
                draft.maxAge = undefined;
            } else {
                draft.maxAge = max;
            }
        }))
    })
);

const SellingOffersFilters = () => {
    const availableCountries = useSellingOfferStore(state => state.availableCountries);
    const setAvailableCountries = useSellingOfferStore(state => state.setAvailableCountries);

    const endDate = useSellingOfferStore(state => state.endDate);
    const startDate = useSellingOfferStore(state => state.startDate);
    const setStartDate = useSellingOfferStore(state => state.setDateRange);

    const {
        gender,
        cooperativeId,
        minAge,
        maxAge,
        setGender,
        setCooperativeId,
        setMinAge,
        setMaxAge,
    } = useSellingOfferStore();

    return (
        <View style={tailwind("flex m-2 w-full flex-row justify-end my-6")}>
            <View style={tailwind('flex-1 flex-row mr-6 justify-end')}>
                {/*<CountryFilter setCountry={(country) => setAvailableCountries([country])}*/}
                {/*               country={availableCountries[0]}/>*/}
                <GenderFilter setGender={setGender} gender={gender}/>
                <CooperativeFilter country={availableCountries[0]} setCooperativeId={setCooperativeId}
                                   cooperativeId={cooperativeId}/>
                <AgeFilter setMinAge={setMinAge} setMaxAge={setMaxAge} minAge={minAge} maxAge={maxAge}/>

                <DateRangePicker
                    label="Date range"
                    style={tailwind('flex-1 mr-2')}
                    placeholder="Pick dates range"
                    value={[new Date(startDate), new Date(endDate)]}
                    onChange={(value: [Date, Date]) => {
                        //if second date is null do not update the date range
                        if (value[1]) {
                            setStartDate(value);
                        }
                    }}
                />
            </View>
        </View>
    )
}

export default () => {
    const startDate = useSellingOfferStore(state => state.startDate);
    const endDate = useSellingOfferStore(state => state.endDate);
    const language = useSellingOfferStore(state => state.language);
    const limit = useSellingOfferStore(state => state.limit);
    const availableCountries = useSellingOfferStore(state => state.availableCountries);
    const {gender, cooperativeId, minAge, maxAge} = useSellingOfferStore();

    const {data, isFetching} = useSellingOfferAggregateFetch({
        startDate,
        endDate,
        limit,
        availableCountries,
        language,
        gender,
        cooperativeId,
        minAge,
        maxAge
    });
    const {data: cooperatives} = useCooperativesFetch(availableCountries[0]);
    const selectedCooperative = (cooperatives || []).find(({id}) => id === cooperativeId);


    const pieData = extractAggregateOffersData(data || []);
    const layout: Partial<Layout> = {
        title: {
            text: generateOfferChartTitle({
                gender,
                userAgeMin: minAge,
                userAgeMax: maxAge,
                startDate,
                endDate,
                includeDate: true,
                cooperativeName: selectedCooperative ? selectedCooperative.name : undefined,
                chartDefaultTitle: 'Posts'
            }),
        }
    };


    return (
        <PageContainer>
            {/*Check if page details is defined*/}

            <View style={tailwind('flex-col items-start justify-between p-4')}>
                <PageTitle title={"Post Products"}/>
                <PageDescription description={"This distribution represents the product popularity of offers."}/>
            </View>

            <SellingOffersFilters/>

            <View>
                <LoadingOverlay visible={isFetching}/>
                <Plot
                    data={[pieData]}
                    layout={layout}
                    onInitialized={(figure: any) => {
                        figure.layout.autosize = true;
                    }}
                    style={{width: '100%', height: '100%'}}
                />
            </View>
        </PageContainer>
    );
}