import React from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {Main} from "./Main";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProtectedRoute from "./pages/ProtectedRoute";
import UserGrowthPage from "./pages/UserGrowthPage";
import SellingOfferProductsPage from "./pages/SellingOfferProductsPage";
import AvailableProductsPage from "./pages/AvailableProductsPage";
import BuyingOfferProductsPage from "./pages/BuyingOfferProductsPage";
import OfferHistoryPage from "./pages/OfferHistoryPage";
import UserMapPage from "./pages/UserMapPage";
import TotalUsersPage from "./pages/TotalUsersPage";
import AvailableProductsMapPage from "./pages/AvailableProductsMapPage";
import BuyingOfferMapPage from "./pages/BuyingOfferMapPage";

export default function MainRouter() {
    return (
        <Router>
            <Routes>
                <Route path={'/'} element={<Navigate replace to={'/app'}/>}/>
                <Route path="/app/*" element={
                    <ProtectedRoute>
                        <Main/>
                    </ProtectedRoute>}>
                    <Route path="home" element={<Home/>}/>
                    <Route path="dashboard">
                        <Route element={<UserMapPage/>} path="user-map"/>
                        <Route element={<UserGrowthPage/>} path="user-growth"/>
                        <Route element={<TotalUsersPage/>} path="total-users"/>
                        <Route element={<SellingOfferProductsPage/>} path="popular-products"/>
                        <Route element={<AvailableProductsPage/>} path="available-products"/>
                        <Route element={<AvailableProductsMapPage/>} path="available-products-map"/>
                        <Route element={<OfferHistoryPage/>} path="offer-history"/>
                        <Route element={<BuyingOfferProductsPage/>} path="offer-products"/>
                        <Route element={<BuyingOfferMapPage/>} path="offer-map"/>
                        <Route path={'*'} element={<Navigate replace to={'./user-growth'}/>}/>
                    </Route>
                    <Route path={'*'} element={<Navigate replace to={'./home'}/>}/>
                </Route>
                <Route path="/login" element={<Login/>}/>
            </Routes>
        </Router>
    );
}