/*
    Component to display the description for page details.
    This component is used to display the description of the page.
    The View style should cover half of the screen to increase the readability of the description.
    The Text tailwind opacity in style property should be set to 60 percent to make the text readable.
 */
import {Text, View} from "react-native";
import {tailwind} from "../tailwind";
import React from "react";

export const PageDescription = ({description}: { description: string }) => {
    return (
        <View style={tailwind('flex-row w-1/2 items-center justify-between py-2')}>
            <Text style={tailwind('text-lg opacity-60')}>{description}</Text>
        </View>
    )
};