import React, {useEffect} from 'react';
import {tailwind} from "../tailwind";
import {View} from "react-native";
import PageContainer from "../components/PageContainer";
import Plot from 'react-plotly.js';
import {getPageDetails, PageDetail} from "../types";
import {useQuery} from "react-query";
import {API, graphqlOperation} from 'aws-amplify';
import {getTotalUsersTimeSeries} from "../graphql/queries";
import {LoadingOverlay} from '@mantine/core';
import dayjs from 'dayjs';
import create, {SetState} from 'zustand';
import produce from "immer";
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
import {useUserGrowthStore} from "./UserGrowthPage";
import {UserFilters} from "../components/UserFilters";
import {getUserColorByType} from "../utils/UserColoring";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import {generateUserChartTitle} from "../utils/charting";
import {convertDateToSearchableFormat} from "../utils/date";

type TotalUsersFilter = {
    country: string,
    endDate: string,
    startDate: string,
    limit: number,
    productId?: string;
    productName: string;
    setLimit: (limit: number) => void;
    setCountry: (country: string) => void;
    setDateRange(dateRange: Date[]): void;
    gender?: string;
    setGender: (gender: string) => void;
    cooperativeId?: string;
    setProduct: (productId: string, productName: string) => void;
    setCooperativeId: (cooperativeId: string) => void;
    minAge?: number;
    maxAge?: number;
    setMinAge: (min: number) => void;
    setMaxAge: (max: number) => void;
}

export const useTotalUserStore = create<TotalUsersFilter>(
    (set: SetState<TotalUsersFilter>) => ({
        country: 'TZ',
        productName: 'All',
        endDate: convertDateToSearchableFormat(dayjs().toDate()),
        startDate: convertDateToSearchableFormat(dayjs().subtract(12, 'month').toDate()),
        limit: 10,
        setCountry: (country: string) => set(draft => {
            draft.country = country;
        }),
        setDateRange: (dateRange: [Date, Date]) => set(draft => {
            draft.startDate = convertDateToSearchableFormat(dateRange[0]);
            draft.endDate = convertDateToSearchableFormat(dateRange[1]);
        }),
        setLimit: (limit: number) => set(produce(draft => {
            draft.limit = limit;
        })),
        setGender: (gender: string) => set(draft => {
            if (gender === 'all') {
                draft.gender = undefined;
                return;
            }
            draft.gender = gender;
        }),
        setCooperativeId: (cooperativeId: string) => set(draft => {
            if (cooperativeId === 'all') {
                draft.cooperativeId = undefined;
                return;
            }
            draft.cooperativeId = cooperativeId;
        }),
        setProduct: (productId: string, productName: string) => set(draft => {
            if (productId === 'all') {
                draft.productId = undefined;
                draft.productName = 'All';
                return;
            }

            draft.productId = productId;
            draft.productName = productName;
        }),
        setMinAge: (min => set(draft => {
            if (min === -1) {
                draft.minAge = undefined;
            } else {
                draft.minAge = min;
            }
        })),
        setMaxAge: (max => set(draft => {
            if (max === -1) {
                draft.maxAge = undefined;
            } else {
                draft.maxAge = max;
            }
        }))
    })
);

interface TotalUsersParams {
    startDate: string;
    endDate: string;
    limit: number;
    country: string;
    gender?: string;
    cooperativeId?: string;
    minAge?: number;
    maxAge?: number;
    productId?: string;
}

const useTotalUsers = (params: TotalUsersParams) => {
    const {startDate, endDate, gender, country, cooperativeId, limit, minAge, maxAge, productId} = params;
    return useQuery(['total-users', startDate, endDate, country, gender, cooperativeId, minAge, maxAge, productId], async (): Promise<any> => {
        const results: any = await API.graphql(graphqlOperation(getTotalUsersTimeSeries, {
            input: {
                startDate,
                endDate,
                country,
                gender,
                cooperativeId,
                minAge,
                maxAge,
                limit,
                productId
            }
        }));
        return Object.keys(results?.data?.getTotalUsersTimeSeries).map((key: string) => {
            const points = results?.data?.getTotalUsersTimeSeries[key];
            let x: string[] = [];
            let y: number[] = [];

            points.forEach((item: any) => {
                x.push(item.timeStampAsString);
                y.push(item.qty);
            })

            return {
                type: 'scatter',
                mode: 'lines+markers',
                name: key,
                line: {
                    color: getUserColorByType(key)
                },
                x,
                y
            };
        });
    });
}

export default () => {

    const pageDetails: PageDetail = getPageDetails('total-users')

    const {
        startDate,
        endDate,
        country,
        limit,
        gender,
        cooperativeId,
        minAge,
        maxAge,
        productName,
        productId,
        setProduct,
        setCountry,
        setDateRange,
        setCooperativeId,
        setGender,
        setMinAge,
        setMaxAge
    } = useUserGrowthStore();


    const {data, isFetching} = useTotalUsers({startDate, endDate, limit, country, cooperativeId, gender, minAge, maxAge, productId});
    const {data: cooperatives} = useCooperativesFetch(country);
    const selectedCooperative = (cooperatives || []).find(({id}) => id === cooperativeId);

    const layout = {
        title: generateUserChartTitle({
            gender,
            startDate,
            endDate,
            userAgeMin: minAge,
            userAgeMax: maxAge,
            chartDefaultTitle: `Total Users${productId ? ` (${productName})`: ''}`,
            cooperativeName: selectedCooperative ? selectedCooperative.name : undefined,
        }),
        xaxis: {
            title: 'Date'
        },
        yaxis: {
            title: 'Number of Users'
        }
    };

    return (
        <PageContainer>
            {/*Check if page details is defined*/}
            {pageDetails &&
                (<View style={tailwind('flex-col items-start justify-between p-4')}>
                    <PageTitle title={pageDetails.title}/>
                    <PageDescription description={pageDetails.description}/>
                </View>)}

            <UserFilters startDate={startDate}
                         endDate={endDate}
                         country={country}
                         gender={gender}
                         cooperativeId={cooperativeId}
                         minAge={minAge}
                         maxAge={maxAge}
                         productId={productId}
                         setProduct={setProduct}
                         setGender={setGender}
                         setCountry={setCountry}
                         setCooperativeId={setCooperativeId}
                         setMinAge={setMinAge}
                         setMaxAge={setMaxAge}
                         setDateRange={setDateRange}/>
            <View>
                <LoadingOverlay visible={isFetching}/>
                <Plot
                    // @ts-ignore
                    data={data || []}
                    layout={layout}
                    useResizeHandler={true}
                    style={{width: '100%', height: '100%'}}
                />
            </View>
        </PageContainer>
    );
}