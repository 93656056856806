// An array Page details for each page in the navConfig
import {PageDetails, Coop, Offer, Order, Post, Price, Product, TreeNode, Producer} from "../types";
const { v4:uuid } = require('uuid');

// An array of 30 producers.
export const producers: Producer[] = [
    {
        id: uuid(),
        name: "Producer 1",
        description: "Description 1",
        image: "https://via.placeholder.com/150",
        coopId: "1",
        createdAt: new Date(),
        updatedAt: new Date(),
    }
];

// An array of 30 products.
export const products: Product[] = [
    {
        id: uuid(),
        name: "Apple",
        description: "A juicy red fruit",
        price: {
            amount: 1.5,
            currency: "USD",
        },
        image: "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
        producerId: producers[0].id,
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: uuid(),
        name: "Banana",
        description: "A yellow fruit",
        price: {
            amount: 2.5,
            currency: "USD",
        },
        producerId: producers[0].id,
        createdAt: new Date(),
        updatedAt: new Date(),
    },
];

export const pageDetails: PageDetails = {
    "home": {
        title: "Home",
        description: "Home",
    },
    "dashboard": {
        title: "Dashboard",
        description: "Dashboard",
    },
    "general": {
        title: "General",
        description: "General",
    },
    "user-growth": {
        title: "User Growth",
        description: "The user growth is measured over time. We add all the users around the world.",
    },
    "total-users": {
        title: "Total Users",
        description: "The total number of Agromovil users over time."
    },
    "user-map": {
        title: "User Map",
        description: "The user map shows the users in the world. The graph is filtered to show only non-coop users.",
    },
    "user-age": {
        title: "User Age",
        description: "The user age is measured over time. We add all the users around the world.",
    },
    "post-history": {
        title: "Post History",
        description: "The post history shows the posts made by the producers.",
    },
    "post-by-city": {
        title: "Post By City",
        description: "The post by city shows the posts made by the producers by city.",
    },
    "producer-map": {
        title: "Producer Map",
        description: "The producer map shows the producers in the world.",
    },
    "offer-history": {
        title: "Offer History",
        description: "The offer history shows the offers made by the buyers.",
    },
    "orders-by-city": {
        title: "Orders By City",
        description: "The orders by city shows the orders made by the buyers by city.",
    },
    "buyers-map": {
        title: "Buyers Map",
        description: "The buyers map shows the all buyers in the world.",
    },
}