import React from 'react';
import {useLocation, Navigate} from "react-router-dom";
import useUser from "../hooks/useUser";

const ProtectedRoute = ({ children }: { children?: JSX.Element }) => {
    const {success} = useUser();
    const location = useLocation();

    if (!success) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }
    if(children === undefined) {
        return null;
    }

    return children;
};

export default ProtectedRoute;