import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getAllCooperatives} from "../graphql/queries";
import {CooperativeOutput} from "../types";

export const useCooperativesFetch = (country: string) => {
    return useQuery(["all-cooperatives", country], async () => {
        const result: any = await API.graphql(graphqlOperation(getAllCooperatives, {
            input: {
                country,
            }}))
        const allResults = result!.data!.getAllCooperatives as CooperativeOutput[];
        return allResults.filter(({name}) => name !== 'None');//TODO: filter this on the backend?
    });
}