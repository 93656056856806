import {OffersTimeSeries} from "../types";
import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getAvailableProductInventoryTimeSeries} from "../graphql/queries";

export interface UseAvailableInventoryTimeSeriesParams {
    startDate: string,
    endDate: string,
    limit: number,
    country: string,
    language: string,
    cooperativeId?: string,
    productId?: string,
    unitId?: number
    gender?: string
    minAge?: number;
    maxAge?: number;
}

export const useAvailableInventoryTimeSeries = (params: UseAvailableInventoryTimeSeriesParams) => {
    const {
        startDate,
        endDate,
        limit,
        country,
        language,
        cooperativeId,
        productId,
        unitId,
        gender,
        minAge,
        maxAge
    } = params;
    return useQuery(['available-inventory-time-series', startDate, endDate, limit, country, language, cooperativeId, productId, unitId, gender, minAge, maxAge], async () => {
        const result: any = await API.graphql(graphqlOperation(getAvailableProductInventoryTimeSeries, {
            input: {
                startDate,
                endDate,
                country,
                limit,
                cooperativeId,
                unitId,
                productId,
                language,
                gender,
                minAge,
                maxAge
            }
        }));

        console.log("useAvailableInventoryTimeSeries : ", result);
        return result!.data!.getAvailableProductInventoryTimeSeries as OffersTimeSeries[];
    });
}