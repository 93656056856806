import React from 'react';
import {View} from "react-native";
import {getColor, tailwind} from "../tailwind";
import {Title, ActionIcon, Select} from "@mantine/core";
import {Feather} from "@expo/vector-icons";
import {useGlobalFilters} from "../hooks/useGlobalFilters";
import {DateRangePicker} from "@mantine/dates";

export interface GlobalFiltersProps {
    onBack: () => void;
}

export const GlobalFilters = ({onBack}: GlobalFiltersProps) => {
    const {
        country,
        setCountry,
        countryOptions,
        endDate,
        startDate,
        setDateRange,
        cooperativeId,
        cooperativeOptions,
        isCooperativesLoading,
        setCooperative
    } = useGlobalFilters();


    const formattedOptions = cooperativeOptions?.map(({id, name}) => ({value: id, label: name})) || [];

    const fullCooperativeOptions = formattedOptions.concat({value: 'all', label: 'All'});


    return (
        <View style={tailwind('w-full flex flex-col')}>
            <View style={tailwind('flex flex-row justify-between')}>
                <View style={tailwind('flex flex-row items-center h-full')}>
                    <Title order={3} style={tailwind('mr-4')}>Filters</Title>
                    <Feather name={'filter'}
                             size={16}
                             color={getColor('text-primary')}/>
                </View>
                <ActionIcon onClick={onBack}>
                    <Feather name={'arrow-left'}
                             size={16}
                             color={getColor('text-primary')}/>
                </ActionIcon>
            </View>

            <View>
                {/*<Select*/}
                {/*    label="Select country"*/}
                {/*    style={tailwind('flex-1 mr-2')}*/}
                {/*    placeholder="Pick one"*/}
                {/*    value={country}*/}
                {/*    onChange={(value: string) => setCountry(value)}*/}
                {/*    zIndex={10000}*/}
                {/*    data={countryOptions.map(({id, label}) => ({value: id, label}))}*/}
                {/*/>*/}
            </View>
            <View>
                <Select
                    data={fullCooperativeOptions}
                    label={isCooperativesLoading ? "loading..." : "Select cooperative"}
                    style={tailwind('flex-1 mr-2')}
                    placeholder="Pick one"
                    zIndex={10000}
                    value={cooperativeId || 'all'}
                    onChange={(value: string) => setCooperative(value)}
                />
            </View>
            <View>
                <DateRangePicker
                    label="Date range"
                    style={tailwind('flex-1 mr-2')}
                    placeholder="Pick dates range"
                    zIndex={10000}
                    value={[new Date(startDate), new Date(endDate)]}
                    onChange={(value: [Date, Date]) => {
                        //if second date is null do not update the date range
                        if (value[1]) {
                            setDateRange(value);
                        }
                    }}/>
            </View>
        </View>
    );
};