import {View} from "react-native";
import {tailwind} from "../tailwind";
import {Select} from "@mantine/core";
import {DateRangePicker, DatePicker} from "@mantine/dates";
import React from "react";
import {useAllProductsFetch} from "../hooks/useAllProductsFetch";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import dayjs from "dayjs";
import {CountryFilter} from "./CountryFilter";
import {ProductFilter} from "./ProductFilter";
import {CooperativeFilter} from "./CooperativeFilter";
import {GenderFilter} from "./GenderFilter";
import {UnitFilter} from "./UnitFilter";
import {AgeFilter} from "./AgeFilter";

export type OfferFiltersProps = {
    country: string;
    date: string;
    productId?: string;
    cooperativeId?: string;
    language: string;
    maxAge?: number;
    minAge?: number;
    gender?: string;
    setGender(gender: string): void;
    setCountry: (country: string) => void;
    setDate(date: Date): void;
    setProduct: (productId: string, productName: string) => void;
    setCooperativeId: (cooperativeId: string) => void;
    setMinAge(min: number): void;
    setMaxAge(max: number): void;
}

export const OfferMapFilters = (props: OfferFiltersProps) => {
    const {
        country,
        date,
        productId,
        cooperativeId,
        maxAge,
        minAge,
        gender,
        setCountry,
        setDate,
        setGender,
        setMaxAge,
        setMinAge,
        setProduct,
        setCooperativeId
    } = props;

    return (
        <View style={tailwind("flex m-2 w-full flex-row justify-end my-6")}>
            <View style={tailwind('flex-1 flex-row mr-6 justify-end')}>
                {/*<CountryFilter setCountry={setCountry} country={country}/>*/}
                <ProductFilter country={country} setProduct={setProduct} productId={productId}/>
                <CooperativeFilter country={country} setCooperativeId={setCooperativeId} cooperativeId={cooperativeId}/>
                <GenderFilter setGender={setGender} gender={gender}/>

                <AgeFilter setMinAge={setMinAge} setMaxAge={setMaxAge} minAge={minAge} maxAge={maxAge}/>

                <DatePicker
                    label="Date"
                    style={tailwind('flex-1 mr-2')}
                    placeholder="Pick dates range"
                    value={dayjs(date).toDate()}
                    firstDayOfWeek="sunday"
                    onChange={(value: Date) => {
                        setDate(value);
                    }}
                />
            </View>
        </View>
    )
}