import React, {ReactChild, ReactNode} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {tailwind} from "../tailwind";
import {Paper, LoadingOverlay, Text} from "@mantine/core";
import {useGlobalFilters} from "../hooks/useGlobalFilters";
import {useUserGrowth} from "../hooks/useUserGrowth";
import {getUserColorByType} from "../utils/UserColoring";
import {area, curveBasis,} from 'd3-shape';
import {scaleLinear, scaleBand} from 'd3-scale';
import Svg, {Circle, G, Line, Path, Rect} from "react-native-svg";

import getMonth from 'date-fns/getMonth';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import {useAvailableInventoryTimeSeries} from "../hooks/useAvailableInventoryTimeSeries";
import {OffersTimeSeries} from "../types";
import {getYear} from "date-fns";
import {useAggregateBuyingOffersTimeSeriesFetch} from "../hooks/useAggregateBuyingOffersTimeSeriesFetch";

const groupDataByMonth = (dataToGroup: ({timeStampAsString: string, qty: number}|OffersTimeSeries)[], startDate:string, endDate:string) => {
    let dataMonths = 0;
    const startingDateObject = new Date(startDate);
    if(dataToGroup.length > 0) {
        dataMonths = differenceInCalendarMonths(new Date(endDate), startingDateObject);
    }

    return dataToGroup.reduce((memo:any[], dataPoint) => {

        const date = new Date(dataPoint.timeStampAsString);

        const finalIndex = dataMonths - differenceInCalendarMonths(date, startingDateObject);

        try {
            memo[finalIndex].qty += dataPoint.qty;
        } catch(e){
            //TODO: add error handling here.
            console.table({dataMonths, finalIndex, date, startingDateObject});
        }

        return memo;
    }, Array.from({ length: dataMonths + 1 }, () => ({ qty: 0 })));
};


const ProducerGrowthGraph = () => {
    const {startDate, endDate, country} = useGlobalFilters();

    const {data, isFetching} = useUserGrowth({startDate, endDate, limit: 250, country});

    const producerData: { timeStampAsString: string, qty: number }[] = (data ? data['producer'] : []);

    const wrangledData = groupDataByMonth(producerData, startDate, endDate);

    const maxValue = wrangledData.reduce((value, {qty}) => value > qty ? value : qty, 0);

    const xScale = scaleLinear([0, wrangledData.length], [0, 100]);
    const yScale = scaleLinear([0, maxValue * 1.25], [2, 100]);

    const chartArea = area<{ qty: number }>().x((d, index) => {
        return xScale(index);
    }).y0(0).y1(d => {
        return yScale(d.qty);
    }).curve(curveBasis);


    return (<View style={[tailwind('w-full'), {flex: 2}]}>
        <LoadingOverlay visible={isFetching}/>
        <Svg width="100%" style={tailwind('')} viewBox={'0 0 100 100'}>
            <G transform={'translate(0,100) scale(1,-1)'}>
                <Path
                    d={chartArea(wrangledData) || ''}
                    fill={getUserColorByType('producer')}
                />
            </G>
        </Svg>
    </View>);
};

const BuyerGrowthGraph = () => {
    const {startDate, endDate, country} = useGlobalFilters();

    const {data, isFetching} = useUserGrowth({startDate, endDate, limit: 250, country});

    const buyerData: { timeStampAsString: string, qty: number }[] = (data ? data['buyer'] : []);

    const wrangledData = groupDataByMonth(buyerData, startDate, endDate);

    const maxValue = wrangledData.reduce((value, {qty}) => value > qty ? value : qty, 0);

    const xScale = scaleLinear([0, wrangledData.length], [0, 100]);
    const yScale = scaleLinear([0, maxValue * 1.25], [2, 100]);

    const chartArea = area<{ timeStampAsString: string, qty: number }>().x((d, index) => {
        return xScale(index);
    }).y0(0).y1(d => yScale(d.qty)).curve(curveBasis);


    return (<View style={[tailwind('w-full'), {flex: 2}]}>
        <LoadingOverlay visible={isFetching}/>
        <Svg width="100%" style={tailwind('')} viewBox={'0 0 100 100'}>
            <G transform={'translate(0,100) scale(1,-1)'}>
                <Path
                    d={chartArea(wrangledData) || ''}
                    fill={getUserColorByType('buyer')}
                />
            </G>
        </Svg>
    </View>);
};

const AvailablePostsGraph = () => {
    const {startDate, endDate, country, language, cooperativeId, productId} = useGlobalFilters();

    const {data, isFetching} = useAvailableInventoryTimeSeries({
        startDate,
        endDate,
        limit: 100,
        country,
        language,
        cooperativeId,
        productId
    });

    const wrangledData = groupDataByMonth(data || [], startDate, endDate);

    const maxValue = wrangledData.reduce((value, {qty}) => value > qty ? value : qty, 0);

    const xScale = scaleLinear([0, wrangledData.length], [0, 100]);
    const yScale = scaleLinear([0, maxValue * 1.25], [2, 100]);

    const chartArea = area<{ timeStampAsString: string, qty: number }>().x((d, index) => {
        return xScale(index);
    }).y0(0).y1(d => yScale(d.qty)).curve(curveBasis);


    return (<View style={[tailwind('w-full'), {flex: 2}]}>
        <LoadingOverlay visible={isFetching}/>
        <Svg width="100%" style={tailwind('')} viewBox={'0 0 100 100'}>
            <G transform={'translate(0,100) scale(1,-1)'}>
                <Path
                    d={chartArea(wrangledData) || ''}
                    fill={getUserColorByType('producer')}
                />
            </G>
        </Svg>
    </View>);
};

const SubmittedOffersGraph = () => {
    const {startDate, endDate, country, language, cooperativeId, productId} = useGlobalFilters();

    const {data, isFetching} = useAggregateBuyingOffersTimeSeriesFetch({
        startDate,
        endDate,
        limit: 100,
        country,
        language,
        cooperativeId,
        productId
    });

    const wrangledData = groupDataByMonth(data || [], startDate, endDate);

    const maxValue = wrangledData.reduce((value, {qty}) => value > qty ? value : qty, 0);

    const xScale = scaleLinear([0, wrangledData.length], [0, 100]);
    const yScale = scaleLinear([0, maxValue * 1.25], [2, 100]);

    const chartArea = area<{ timeStampAsString: string, qty: number }>().x((d, index) => {
        return xScale(index);
    }).y0(0).y1(d => yScale(d.qty)).curve(curveBasis);


    return (<View style={[tailwind('w-full'), {flex: 2}]}>
        <LoadingOverlay visible={isFetching}/>
        <Svg width="100%" style={tailwind('')} viewBox={'0 0 100 100'}>
            <G transform={'translate(0,100) scale(1,-1)'}>
                <Path
                    d={chartArea(wrangledData) || ''}
                    fill={getUserColorByType('buyer')}
                />
            </G>
        </Svg>
    </View>);
};


const getTitle = (type: DashboardGraphType): string => {
    switch (type) {
        case DashboardGraphType.AvailablePosts:
            return 'Available Posts';
        case DashboardGraphType.BuyerGrowth:
            return 'Buyer Growth';
        case DashboardGraphType.ProducerGrowth:
            return 'Producer Growth';
        case DashboardGraphType.SubmittedOffers:
            return 'Submitted Offers';
    }
    return '';
}

const getGraph = (type: DashboardGraphType): React.ComponentType => {
    switch (type) {
        case DashboardGraphType.AvailablePosts:
            return AvailablePostsGraph;
        case DashboardGraphType.BuyerGrowth:
            return BuyerGrowthGraph;
        case DashboardGraphType.ProducerGrowth:
            return ProducerGrowthGraph;
        case DashboardGraphType.SubmittedOffers:
            return SubmittedOffersGraph;
    }
}

export enum DashboardGraphType {
    ProducerGrowth = 'ProducerGrowth',
    BuyerGrowth = 'BuyerGrowth',
    AvailablePosts = 'AvailablePosts',
    SubmittedOffers = 'SubmittedOffers'
}

export interface DashboardGraphProps {
    type: DashboardGraphType;
    onPress: () => void;
}


export const DashboardGraph = ({type, onPress}: DashboardGraphProps) => {
    const title = getTitle(type);
    const Graph = getGraph(type);
    return (
        <TouchableOpacity onPress={onPress}>
            <Paper padding="lg" shadow="sm" style={tailwind('flex-col items-center h-52 w-52 mr-16 flex')}>
                <Graph/>
                <Text style={tailwind('pt-2')}>{title}</Text>
            </Paper>
        </TouchableOpacity>
    );
};