import React, {useEffect} from 'react';
import {tailwind} from "../tailwind";
import {View} from "react-native";
import PageContainer from "../components/PageContainer";
import Plot from 'react-plotly.js';
import {getPageDetails, PageDetail} from "../types";
import {LoadingOverlay} from '@mantine/core';
import dayjs from 'dayjs';
import create, {SetState} from 'zustand';
import produce from "immer";
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
import {UserFilters} from "../components/UserFilters";
import {getUserColorByType} from "../utils/UserColoring";
import {useUserGrowth} from "../hooks/useUserGrowth";
import {generateUserChartTitle} from "../utils/charting";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import {convertDateToSearchableFormat} from "../utils/date";

type UserGrowthFilter = {
    country: string,
    endDate: string,
    startDate: string,
    limit: number,
    productId?: string;
    productName: string;
    setProduct: (productId: string, productName: string) => void;
    setLimit: (limit: number) => void;
    setCountry: (country: string) => void;
    setDateRange(dateRange: Date[]): void;
    gender?: string;
    setGender: (gender: string) => void;
    cooperativeId?: string;
    setCooperativeId: (cooperativeId: string) => void;
    minAge?:number;
    maxAge?:number;
    setMinAge: (min:number) => void;
    setMaxAge: (max:number) => void;
}

export const useUserGrowthStore = create<UserGrowthFilter>(
    (set: SetState<UserGrowthFilter>) => ({
        country: 'TZ',
        endDate: convertDateToSearchableFormat(dayjs().toDate()),
        startDate: convertDateToSearchableFormat(dayjs().subtract(12, 'month').toDate()),
        limit: 10,
        productName: 'All',
        setCountry: (country: string) => set(draft => {
            draft.country = country;
        }),
        setDateRange: (dateRange: [Date, Date]) => set(draft => {
            draft.startDate = convertDateToSearchableFormat(dateRange[0]);
            draft.endDate = convertDateToSearchableFormat(dateRange[1]);
        }),
        setLimit: (limit: number) => set(produce(draft => {
            draft.limit = limit;
        })),
        setGender: (gender: string) => set(draft => {
            if (gender === 'all') {
                draft.gender = undefined;
                return;
            }
            draft.gender = gender;
        }),
        setProduct: (productId: string, productName: string) => set(draft => {
            if (productId === 'all') {
                draft.productId = undefined;
                draft.productName = 'All';
                return;
            }

            draft.productId = productId;
            draft.productName = productName;
        }),
        setCooperativeId: (cooperativeId: string) => set(draft => {
            if (cooperativeId === 'all') {
                draft.cooperativeId = undefined;
                return;
            }
            draft.cooperativeId = cooperativeId;
        }),
        setMinAge: (min => set(draft => {
            if(min === -1) {
                draft.minAge = undefined;
            } else {
                draft.minAge = min;
            }
        })),
        setMaxAge: (max => set(draft => {
            if(max === -1) {
                draft.maxAge = undefined;
            } else {
                draft.maxAge = max;
            }
        }))
    })
);


export default () => {

    const pageDetails: PageDetail = getPageDetails('user-growth')

    const {
        startDate,
        endDate,
        country,
        limit,
        cooperativeId,
        gender,
        minAge,
        maxAge,
        productId,
        productName,
        setCountry,
        setDateRange,
        setCooperativeId,
        setGender,
        setMinAge,
        setMaxAge,
        setProduct
    } = useUserGrowthStore();

    const {data, isFetching} = useUserGrowth({startDate, endDate, limit, country, cooperativeId, gender, minAge, maxAge, productId});

    const {data: cooperatives} = useCooperativesFetch(country);
    const selectedCooperative = (cooperatives || []).find(({id}) => id === cooperativeId);


    const wrangledData = data ? Object.keys(data).map((key: string) => {
        const points = data[key];
        let x: string[] = [];
        let y: number[] = [];

        points.forEach((item: any) => {
            x.push(item.timeStampAsString);
            y.push(item.qty);
        });

        return {
            type: 'scatter',
            mode: 'lines+markers',
            line: {
                color: getUserColorByType(key)
            },
            name: key,
            x,
            y
        };
    }) : null;


    const layout = {
        title: generateUserChartTitle({
            gender,
            startDate,
            endDate,
            userAgeMin: minAge,
            userAgeMax: maxAge,
            chartDefaultTitle: `User Growth${productId ? ` (${productName})`: ''}`,
            cooperativeName: selectedCooperative ? selectedCooperative.name : undefined,
        }),
        xaxis: {
            title: 'Date'
        },
        yaxis: {
            title: 'Number of Users'
        }
    };

    return (
        <PageContainer>
            {/*Check if page details is defined*/}
            {pageDetails &&
                (<View style={tailwind('flex-col items-start justify-between p-4')}>
                    <PageTitle title={pageDetails.title}/>
                    <PageDescription description={pageDetails.description}/>
                </View>)}

            <UserFilters startDate={startDate}
                         endDate={endDate}
                         country={country}
                         productId={productId}
                         cooperativeId={cooperativeId}
                         gender={gender}
                         minAge={minAge}
                         maxAge={maxAge}
                         setCountry={setCountry}
                         setCooperativeId={setCooperativeId}
                         setGender={setGender}
                         setMinAge={setMinAge}
                         setMaxAge={setMaxAge}
                         setProduct={setProduct}
                         setDateRange={setDateRange}/>

            <View>
                <LoadingOverlay visible={isFetching}/>
                <Plot
                    // @ts-ignore
                    data={wrangledData || []}
                    layout={layout}
                    useResizeHandler={true}
                    style={{width: '100%', height: '100%'}}
                />
            </View>
        </PageContainer>
    );
}