import createStore from 'zustand'
import {GlobalFilters} from "../components/GlobalFilters";
import dayjs from "dayjs";
import {useCooperativesFetch} from "./useCooperativesFetch";
import {CooperativeOutput} from "../types";
import {bool} from "yup";
import {convertDateToSearchableFormat} from "../utils/date";

export interface GlobalFiltersState {
    language: string;
    country: string;
    cooperativeId?: string;
    productId?: string;
    productName: string;
    endDate: string;
    startDate: string;
    setCountry: (country: string) => void;
    setCooperative: (cooperativeId: string) => void;
    setDateRange: (dateRange: Date[]) => void;
}

export const useGlobalFilterStore = createStore<GlobalFiltersState>((set) => ({
    language: 'en',
    country: 'TZ',
    productName: "All",
    endDate: convertDateToSearchableFormat(dayjs().add(0, 'month').toDate()),
    startDate: convertDateToSearchableFormat(dayjs().add(0, 'month').subtract(12, 'month').toDate()),
    setCooperative: (cooperativeId) => set(draft => {
        draft.cooperativeId = cooperativeId;
    }),
    setCountry: (country) => set(draft => {
        draft.country = country;
    }),
    setDateRange: (dateRange) => set(draft => {
        draft.startDate = convertDateToSearchableFormat(dateRange[0])
        draft.endDate = convertDateToSearchableFormat(dateRange[1])
    }),
}));

export interface CountryOption {
    id: string;
    label: string;
}

const countryOptions: CountryOption[] = [
    {
        id: 'CO',
        label: 'Colombia'
    },
    {
        id: 'TZ',
        label: 'Tanzania'
    }
];

export interface GlobalFilters extends GlobalFiltersState {
    countryOptions: CountryOption[],
    cooperativeOptions: CooperativeOutput[]
    isCooperativesLoading: boolean
}

export const useGlobalFilters = (): GlobalFilters => {
    const {
        language,
        country,
        cooperativeId,
        productId,
        productName,
        endDate,
        startDate,
        setCooperative,
        setCountry,
        setDateRange
    } = useGlobalFilterStore();


    const {data: cooperativeOptions, isFetching} = useCooperativesFetch(country);

    return {
        //language
        language,

        //Country
        country,
        countryOptions,
        setCountry,

        //Cooperative
        cooperativeId,
        cooperativeOptions: cooperativeOptions || [],
        setCooperative,
        isCooperativesLoading: isFetching,

        productId,
        productName,

        //Dates
        endDate,
        startDate,
        setDateRange
    };
};
