import React from 'react';
import {tailwind} from "../tailwind";
import {View} from "react-native";
import PageContainer from "../components/PageContainer";
import Plot from 'react-plotly.js';
import {PageTitle} from "../components/PageTitle";
import {PageDescription} from "../components/PageDescription";
import {useUsersByCity} from "../hooks/userUsersByCityFetch";
import create, {SetState} from "zustand";
import dayjs from "dayjs";
import {LoadingOverlay} from "@mantine/core";
import {UserFilters} from "../components/UserFilters";
import {Layout} from "plotly.js";
import {generateUserChartTitle} from "../utils/charting";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";
import {centerCoord} from "../utils/map";
import {convertDateToSearchableFormat} from "../utils/date";


type Location = {
    lat: number;
    long: number;
}

type RoleAgg = {
    "role": string,
    "count": number
}

type Place = {
    "location": Location,
    "cityName": string,
    "total": number,
    "count": number,
    "byRole": RoleAgg[]
}

type Trace = {
    name: string,
    lon: number,
    lat: number,
    count: number,
}

const maybePluralize = (count: number, noun: string, suffix: string = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

const formatTraces = (places?: Place[]): any => {
    const lon = places ? places.map(f => f.location.long) : [];
    const lat = places ? places.map(f => f.location.lat) : [];
    const producers: Trace[] = [];
    const buyers: Trace[] = [];
    const producersText: string[] = [];
    const buyersText: string[] = [];
    places?.forEach(place => {
        place.byRole.forEach(role => {
            if (role.role === 'PRODUCER') {
                producers.push({
                    name: place.cityName,
                    lon: place.location.long,
                    lat: place.location.lat,
                    count: role.count
                })
                producersText.push(`${place.cityName} ${maybePluralize(role.count, 'Producer')}`);
            } else if (role.role === 'BUYER') {
                buyers.push({
                    name: place.cityName,
                    lon: place.location.long,
                    lat: place.location.lat,
                    count: role.count
                })
                buyersText.push(`${place.cityName} ${maybePluralize(role.count, 'Buyer')}`);
            }
        })
    });

    const data = [
        {
            type: "scattermapbox",
            name: "Producers",
            lon,
            lat,
            hoverinfo: "text",
            text: producersText,
            marker: {
                size: producers.map(f => f.count),
                color: '#7CAE75',
                opacity: 0.5,
                sizemode: 'area',
                sizeref: 2. * Math.max(...producers.map(f => f.count)) / (40. ** 2),
                sizemin: 10,
                line: {
                    color: 'white',
                    width: 2
                }
            }
        },
        {
            type: "scattermapbox",
            name: "Buyers",
            lon,
            lat,
            hoverinfo: "text",
            text: buyersText,
            marker: {
                size: buyers.map(f => f.count),
                color: '#F78D1E',
                opacity: 0.5,
                sizemode: 'area',
                sizeref: 2. * Math.max(...buyers.map(f => f.count)) / (40. ** 2),
                sizemin: 10,
                line: {
                    color: 'white',
                    width: 2
                }
            }
        }
    ];

    return {data};
}



type UserMapFilter = {
    country: string,
    endDate: string,
    startDate: string,
    setCountry: (country: string) => void;
    setDateRange(dateRange: Date[]): void;
    gender?: string;
    productId?: string;
    productName: string;
    setGender: (gender: string) => void;
    cooperativeId?: string;
    setProduct: (productId: string, productName: string) => void;
    setCooperativeId: (cooperativeId: string) => void;
    minAge?:number;
    maxAge?:number;
    setMinAge: (min:number) => void;
    setMaxAge: (max:number) => void;
}

export const useUserMapStore = create<UserMapFilter>(
    (set: SetState<UserMapFilter>) => ({
        country: 'TZ',
        productName: 'All',
        endDate: convertDateToSearchableFormat(dayjs().toDate()),
        startDate: convertDateToSearchableFormat(dayjs().subtract(12, 'month').toDate()),
        setCountry: (country: string) => set(draft => {
            draft.country = country;
        }),
        setDateRange: (dateRange: [Date, Date]) => set(draft => {
            draft.startDate = convertDateToSearchableFormat(dateRange[0]);
            draft.endDate = convertDateToSearchableFormat(dateRange[1]);
        }),
        setGender: (gender: string) => set(draft => {
            if (gender === 'all') {
                draft.gender = undefined;
                return;
            }
            draft.gender = gender;
        }),
        setProduct: (productId: string, productName: string) => set(draft => {
            if (productId === 'all') {
                draft.productId = undefined;
                draft.productName = 'All';
                return;
            }

            draft.productId = productId;
            draft.productName = productName;
        }),
        setCooperativeId: (cooperativeId: string) => set(draft => {
            if (cooperativeId === 'all') {
                draft.cooperativeId = undefined;
                return;
            }
            draft.cooperativeId = cooperativeId;
        }),
        setMinAge: (min => set(draft => {
            if(min === -1) {
                draft.minAge = undefined;
            } else {
                draft.minAge = min;
            }
        })),
        setMaxAge: (max => set(draft => {
            if(max === -1) {
                draft.maxAge = undefined;
            } else {
                draft.maxAge = max;
            }
        }))
    })
);

export default () => {
    const {
        endDate,
        startDate,
        country,
        gender,
        cooperativeId,
        minAge,
        maxAge,
        productName,
        productId,
        setCountry,
        setDateRange,
        setGender,
        setCooperativeId,
        setMinAge,
        setMaxAge,
        setProduct
    } = useUserMapStore();


    const {isFetching, data: places} = useUsersByCity({
        startDate,
        endDate,
        limit: 1000,
        country,
        cooperativeId,
        gender,
        minAge,
        maxAge,
        productId
    });

    const {data} = formatTraces(places);
    const {data: cooperatives} = useCooperativesFetch(country);
    const selectedCooperative = (cooperatives || []).find(({id}) => id === cooperativeId);

    const layout:Partial<Layout> = {
        title: {
            text: generateUserChartTitle({
                gender,
                startDate,
                endDate,
                userAgeMin: minAge,
                userAgeMax: maxAge,
                chartDefaultTitle: `Users${productId ? ` (${productName})`: ''}`,
                cooperativeName: selectedCooperative ? selectedCooperative.name : undefined,
                includeDate: true
            }),
            yanchor: "middle"
        },
        mapbox: {
            style: "streets",
            zoom: 5,
            center: centerCoord
        },
        margin: {
            l: 0,
            b: 0,
            t: 32
        }
    };

    const config = {
        mapboxAccessToken: "pk.eyJ1IjoianNpbW9uLWZhdWx0bGluZSIsImEiOiJjazV4ZGRiaTIwMTFsM25xcjcwc3NzaXF6In0.girv7O3wD41pMqlxYHFj0A"
    };

    return (
        <PageContainer>
            <View style={tailwind('flex-col items-start justify-between p-4')}>
                <PageTitle title={"User Map"}/>
                <PageDescription description={"A map of the location for a the users in Agromovil"}/>
            </View>

            <UserFilters startDate={startDate}
                         endDate={endDate}
                         country={country}
                         gender={gender}
                         cooperativeId={cooperativeId}
                         minAge={minAge}
                         maxAge={maxAge}
                         productId={productId}
                         setProduct={setProduct}
                         setGender={setGender}
                         setCountry={setCountry}
                         setCooperativeId={setCooperativeId}
                         setMinAge={setMinAge}
                         setMaxAge={setMaxAge}
                         setDateRange={setDateRange}/>

            <View>
                <LoadingOverlay visible={isFetching}/>
                <Plot
                    // @ts-ignore
                    data={data}
                    layout={layout}
                    config={config}
                    style={{width: '100%', height: 600}}
                />
            </View>
        </PageContainer>
    );
}