import React from 'react';
import {View} from "react-native";
import {tailwind} from "./tailwind";
import Sidebar from "./components/Sidebar";
import {Outlet, useLocation} from "react-router-dom";
import {AppBar} from "./components/AppBar";
import SubNav from "./components/SubNav";

export const Main = () => {

    const location = useLocation();

    if (location.pathname.includes('/home')) {
        return (
            <View style={tailwind('h-full w-full flex-col')}>
                <AppBar/>
                <View style={tailwind('flex-1 flex-row')}>
                    <View style={tailwind('w-24')}>
                        <Sidebar/>
                    </View>
                    <View style={tailwind('flex-1')}>
                        <Outlet/>
                    </View>
                </View>
            </View>
        );
    }

    return (
        <View style={tailwind('flex h-full w-full flex-col')}>
            <AppBar/>
            <View style={tailwind('flex-1 flex-row')}>
                <View style={tailwind('w-24')}>
                    <Sidebar/>
                </View>
                <View style={tailwind('w-48')}>
                    <SubNav/>
                </View>
                <View style={tailwind('flex-1')}>
                    <Outlet/>
                </View>
            </View>
        </View>
    );
};