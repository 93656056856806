import React from 'react';
import {Select} from "@mantine/core";
import {tailwind} from "../tailwind";
import {useAllProductsFetch} from "../hooks/useAllProductsFetch";

export interface ProductFilterProps {
    country: string;
    productId?: string;
    setProduct(productId: string, productLabel:string): void;
}

export const ProductFilter = ({country, productId, setProduct}: ProductFilterProps) => {
    const {data, isFetching} = useAllProductsFetch(country, 'en');

    const productOptions = data?.map(({id, productName}) => ({value: id, label: productName})) || [];

    const fullProductOptions = [{value: 'all', label: 'All'}].concat(productOptions);

    return (<Select
        data={fullProductOptions}
        label={isFetching ? "loading..." : "Select Product"}
        disabled={isFetching}
        style={tailwind('flex-1 mr-2')}
        placeholder="Pick one"
        value={productId || 'all'}
        onChange={(value: string) => {
            setProduct(value, data?.find(({id}) => id === value)?.productName || '')
        }}
    />)
};