import React, {ReactNode} from 'react';
import {tailwind} from "../tailwind";
import {View} from "react-native";

export default ({children}: {children: ReactNode}) => {
    return (
        <View style={tailwind('h-full w-full py-10 px-20 bg-gray-100 overflow-scroll')}>
            {children}
        </View>
    )
}