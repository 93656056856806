import {OffersTimeSeries} from "../types";
import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";

export interface UseBuyingOffersTimeSeriesFetchParams {
    startDate: string;
    endDate: string;
    limit:number;
    country: string;
    language: string;
    cooperativeId?: string;
    productId?: string;
    unitId?:number;
    gender?:string;
    minAge?:number;
    maxAge?:number;
}

export const useAggregateBuyingOffersTimeSeriesFetch = (params:UseBuyingOffersTimeSeriesFetchParams) => {
    const {
        startDate,
        endDate,
        country,
        limit,
        cooperativeId,
        productId,
        language,
        gender,
        minAge,
        maxAge,
        unitId
    } = params;

    return useQuery(['aggregate-buying-offers-time-series', startDate, endDate, limit, country, language, cooperativeId, productId, gender, minAge, maxAge], async () => {
        const query = unitId ? 'getAggregateQtyBuyingOffersTimeSeries' : 'getAggregateBuyingOffersTimeSeries';

        const result: any = await API.graphql(graphqlOperation(queries[query], {
            input: {
                startDate,
                endDate,
                country,
                limit,
                cooperativeId,
                productId,
                language,
                gender,
                minAge,
                maxAge,
                unitId
            }
        }));
        return result!.data![query] as OffersTimeSeries[];
    });
}