import React from 'react';
import * as unitInfo from '../data/units.json';
import {tailwind} from "../tailwind";
import {Select} from "@mantine/core";


export interface UnitFilterProps {
    country: string;
    unit?: number;
    setUnit: (unit: number) => void;
}

export const UnitFilter = ({country, unit, setUnit}: UnitFilterProps) => {
    const englishUnits = unitInfo.en;

    const unitOptions: { value: string, label: string }[] = [{
        value: 'all',
        label: 'All'
    }].concat(englishUnits.filter(({availableCountries}) => {
        return availableCountries.indexOf(country) !== -1;
    }).map(({id, label}) => {
        return {
            value: id,
            label
        }
    }));

    return (
        <Select
            data={unitOptions}
            label={"Select Unit"}
            style={tailwind('flex-1 mr-2')}
            placeholder="Pick one"
            value={unit ? unit.toString() : 'all'}
            onChange={(value: string) => setUnit(value === 'all' ? -1 : parseInt(value))}
        />
    );
};