/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSellingOffersByProduct = /* GraphQL */ `
  query GetSellingOffersByProduct($input: ProductAggregationInput!) {
    getSellingOffersByProduct(input: $input) {
      productName
      count
    }
  }
`;
export const getBuyingOffersByProduct = /* GraphQL */ `
  query GetBuyingOffersByProduct($input: ProductAggregationInput!) {
    getBuyingOffersByProduct(input: $input) {
      productName
      count
    }
  }
`;
export const getAggregateSellingOffersByUnitType = /* GraphQL */ `
  query GetAggregateSellingOffersByUnitType($input: UnitAggregationInput!) {
    getAggregateSellingOffersByUnitType(input: $input) {
      unitName
      count
    }
  }
`;
export const getAggregateBuyingOffersByUnitType = /* GraphQL */ `
  query GetAggregateBuyingOffersByUnitType($input: UnitAggregationInput!) {
    getAggregateBuyingOffersByUnitType(input: $input) {
      unitName
      count
    }
  }
`;
export const getAggregateProductsTimeSeries = /* GraphQL */ `
  query GetAggregateProductsTimeSeries($input: SearchAggregationInput!) {
    getAggregateProductsTimeSeries(input: $input) {
      userName
      count
    }
  }
`;
export const getAggregateBuyersByBuyingOffers = /* GraphQL */ `
  query GetAggregateBuyersByBuyingOffers($input: SearchAggregationInput!) {
    getAggregateBuyersByBuyingOffers(input: $input) {
      userName
      count
    }
  }
`;
export const getAggregateProducersByBuyingOffers = /* GraphQL */ `
  query GetAggregateProducersByBuyingOffers($input: SearchAggregationInput!) {
    getAggregateProducersByBuyingOffers(input: $input) {
      userName
      count
    }
  }
`;
export const getAggregateBuyerByOrders = /* GraphQL */ `
  query GetAggregateBuyerByOrders($input: SearchAggregationInput!) {
    getAggregateBuyerByOrders(input: $input) {
      userName
      count
    }
  }
`;
export const getAggregateProducersByOrders = /* GraphQL */ `
  query GetAggregateProducersByOrders($input: SearchAggregationInput!) {
    getAggregateProducersByOrders(input: $input) {
      userName
      count
    }
  }
`;
export const getAggregateSellingOffersTimeSeries = /* GraphQL */ `
  query GetAggregateSellingOffersTimeSeries($input: OfferParamBodyInput!) {
    getAggregateSellingOffersTimeSeries(input: $input) {
      timeStampAsNumber
      timeStampAsString
      label
      qty
    }
  }
`;
export const getAggregateBuyingOffersTimeSeries = /* GraphQL */ `
  query GetAggregateBuyingOffersTimeSeries($input: OfferParamBodyInput!) {
    getAggregateBuyingOffersTimeSeries(input: $input) {
      timeStampAsNumber
      timeStampAsString
      label
      qty
    }
  }
`;
export const getAggregateQtySellingOffersTimeSeries = /* GraphQL */ `
  query GetAggregateQtySellingOffersTimeSeries($input: OfferParamBodyInput!) {
    getAggregateQtySellingOffersTimeSeries(input: $input) {
      timeStampAsNumber
      timeStampAsString
      label
      qty
    }
  }
`;
export const getAggregateQtyBuyingOffersTimeSeries = /* GraphQL */ `
  query GetAggregateQtyBuyingOffersTimeSeries($input: OfferParamBodyInput!) {
    getAggregateQtyBuyingOffersTimeSeries(input: $input) {
      timeStampAsNumber
      timeStampAsString
      label
      qty
    }
  }
`;
export const getBuyingOffersQtyByCity = /* GraphQL */ `
  query GetBuyingOffersQtyByCity($input: OfferParamBodyInput!) {
    getBuyingOffersQtyByCity(input: $input) {
      timeStampAsNumber
      timeStampAsString
      cities {
        location {
          lat
          long
        }
        cityName
        qty
      }
    }
  }
`;
export const getAllProducts = /* GraphQL */ `
  query GetAllProducts($input: AllProductsInput!) {
    getAllProducts(input: $input) {
      id
      productId
      productName
    }
  }
`;
export const getAllCooperatives = /* GraphQL */ `
  query GetAllCooperatives($input: AllCooperativeInput!) {
    getAllCooperatives(input: $input) {
      id
      name
    }
  }
`;
export const getUserGrowthTimeSeries = /* GraphQL */ `
  query GetUserGrowthTimeSeries($input: UserAggregationInput!) {
    getUserGrowthTimeSeries(input: $input) {
      producer {
        timeStampAsNumber
        timeStampAsString
        label
        qty
      }
      buyer {
        timeStampAsNumber
        timeStampAsString
        label
        qty
      }
    }
  }
`;
export const getTotalUsersTimeSeries = /* GraphQL */ `
  query GetTotalUsersTimeSeries($input: UserAggregationInput!) {
    getTotalUsersTimeSeries(input: $input) {
      producer {
        timeStampAsNumber
        timeStampAsString
        label
        qty
      }
      buyer {
        timeStampAsNumber
        timeStampAsString
        label
        qty
      }
    }
  }
`;
export const getUsersByCity = /* GraphQL */ `
  query GetUsersByCity($input: UserAggregationInput!) {
    getUsersByCity(input: $input) {
      location {
        lat
        long
      }
      cityName
      total
      byRole {
        role
        count
      }
    }
  }
`;
export const getAvailableProductInventoryTimeSeries = /* GraphQL */ `
  query GetAvailableProductInventoryTimeSeries($input: OfferParamBodyInput!) {
    getAvailableProductInventoryTimeSeries(input: $input) {
      timeStampAsNumber
      timeStampAsString
      label
      qty
    }
  }
`;
export const getAvailableProductInventoryByCity = /* GraphQL */ `
  query GetAvailableProductInventoryByCity($input: OfferParamBodyInput!) {
    getAvailableProductInventoryByCity(input: $input) {
      timeStampAsNumber
      timeStampAsString
      cities {
        location {
          lat
          long
        }
        cityName
        qty
      }
    }
  }
`;
