import {OfferAggregate} from "../types";
import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getAggregateBuyingOffersByUnitType, getAggregateSellingOffersByUnitType} from "../graphql/queries";

export const useAggregateBuyingOffersByUnitTypeFetch = (startDate: string, endDate: string, limit: number, country: string, language: string = 'en') => {
    return useQuery(['buying-offer-by-unit-query', startDate, endDate, limit, country, language], async () => {
        const result: any = await API.graphql(graphqlOperation(getAggregateBuyingOffersByUnitType, {
            input: {
                startDate,
                endDate,
                country,
                limit,
                language
            },
        }));
        // console.log("getAggregateBuyingOffersByUnitType : ", result);
        return result!.data!.getAggregateBuyingOffersByUnitType as OfferAggregate[];
    });
}