import {pageDetails} from "./data/dummy";

export type PageDetail = {
  title: string;
  description: string;
  keywords?: string;
};

export type PageDetails = {
  [key: string]: PageDetail;
};

// Component with sub navigation and links to pages nested within Home and Dashboard screens
export interface TreeNode {
    [key: string]: any // type for unknown keys.
    pageDetails?: PageDetail;
    children?: TreeNode[] // type for a known property.
}

export const navConfig: TreeNode = {
    name: "main",
    children: [
        {
            name: "home",
            children: [],
        },
        {
            name: "dashboard",
            children: [
                {
                    name: "users",
                    children: [
                        {
                            name: "user-growth",
                            pageDetails: {
                                title: "User Growth",
                                description: "User Growth",
                            },
                        },
                        {
                            name: "total-users",
                            pageDetails: {
                                title: "Total Users",
                                description: "The total number of users in the system over a given time period.",
                            },
                        },
                        {
                            name: "user-map",
                            pageDetails: {
                                title: "User Map",
                                description: "A map of the location for a the users in Agromovil",
                            },
                        },
                    ],
                },
                {
                    name: "posts",
                    children: [
                        {
                            name: "available-products",
                        },
                        {
                            name: "available-products-map"
                        },
                        {
                            name: "popular-products",
                        },
                    ],
                },
                {
                    name: "offers",
                    children: [
                        {
                            name: "offer-history",
                        },
                        {
                            name: "offer-products",
                        },
                        {
                            name: 'offer-map'
                        }
                    ],
                },
            ],
        },
    ],
}

// The application has two roles for the user: Buyers and Producers.
export enum Role {
    Buyer = "Buyer",
    Producer = "Producer",
}

// Price is a number and a currency.
export type Price = {
    amount: number;
    currency: string;
};

// Product are the items that are sold by the producers. Most of the time, it is fruits and vegetables.
export interface Product {
    id: string;
    name: string;
    description: string;
    price: Price;
    image?: string;
    producerId: string;
    createdAt: Date;
    updatedAt: Date;
}

// Buyers can make offers to producers.
export interface Offer {
    id: string;
    productId: string;
    buyerId: string;
    producerId: string;
    quantity: number;
    price: Price;
    createdAt: Date;
    updatedAt: Date;
}

// Producers can post new products and can accept offers from buyers.
export interface Post {
    id: string;
    productId: string;
    producerId: string;
    quantity: number;
    price: Price;
    createdAt: Date;
    updatedAt: Date;
}

// Orders when a producer accepts an offer from a buyer.
export interface Order {
    id: string;
    offerId: string;
    buyerId: string;
    producerId: string;
    quantity: number;
    price: Price;
    createdAt: Date;
    updatedAt: Date;
}

// A Coop is a group of producers.
export interface Coop {
    id: string;
    name: string;
    description: string;
    image: string;
    createdAt: Date;
    updatedAt: Date;
}

// Non-coops are producers who are not part of a coop.
export interface Producer {
    id: string;
    name: string;
    description: string;
    image: string;
    coopId: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface OffersTimeSeries {
    timeStampAsNumber: number;
    timeStampAsString: string;
    label: string;
    qty: number;
}


// Function to get the page details for a given page name
export const getPageDetails = (pageName: string): PageDetail => {
    return pageDetails[pageName];
}


export type OfferAggregate = {
    productName: string;
    count: number;
}

export type OfferUsers = {
    userName: string;
    count: number;
}
export type ProductOutput = {
    id: string;
    productId: string, // internal id of the product
    productName: string // name of the product by the language
}
export type CooperativeOutput = {
    id: string;
    name: string;
};