import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getUserGrowthTimeSeries} from "../graphql/queries";
import {getUserColorByType} from "../utils/UserColoring";

export interface userGrowthParams {
    startDate: string,
    endDate: string,
    limit: number,
    country: string,
    gender?: string,
    cooperativeId?: string,
    minAge?: number,
    maxAge?: number,
    productId?:string;
}

export const useUserGrowth = (params: userGrowthParams) => {
    const {startDate, endDate, country, limit, gender, cooperativeId, minAge, maxAge, productId} = params;
    return useQuery(['user-growth', startDate, endDate, country, gender, cooperativeId, minAge, maxAge, productId], async (): Promise<any> => {
        const results: any = await API.graphql(graphqlOperation(getUserGrowthTimeSeries, {
            input: {
                startDate,
                endDate,
                country,
                gender,
                cooperativeId,
                limit,
                minAge,
                maxAge,
                productId
            }
        }));
        return results?.data?.getUserGrowthTimeSeries;
    });
}