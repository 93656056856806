

import React, {useEffect} from 'react';
import {Amplify} from "aws-amplify";
import { QueryClientProvider, QueryClient } from 'react-query'
import awsconfig from './src/awsexports';
import MainRouter from "./src/MainRouter";
import AppLoading from 'expo-app-loading';
import {
    useFonts,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_600SemiBold_Italic,
    Poppins_700Bold,
    Poppins_900Black,
} from '@expo-google-fonts/poppins';
import useUser from "./src/hooks/useUser";
import './src/styles/index.css';

// Configure AWS Amplify
Amplify.configure(awsconfig);

const queryClient = new QueryClient()


export default function App() {
    const {initialLoad, initialLoading} = useUser();

    useEffect(() => {
        initialLoad();
    }, []);

    let [fontsLoaded] = useFonts({
        Poppins_400Regular,
        Poppins_500Medium,
        Poppins_600SemiBold,
        Poppins_600SemiBold_Italic,
        Poppins_700Bold,
        Poppins_900Black,
    });

    if (!fontsLoaded || initialLoading) {
        return <AppLoading />;
    } else {
        return (
            <QueryClientProvider client={queryClient}>
                <MainRouter/>
            </QueryClientProvider>
        );
    }

}
