import React from "react";
import {View} from "react-native";
import {tailwind} from "../tailwind";
import useUser from "../hooks/useUser";
import { Menu, Text, UnstyledButton } from '@mantine/core';
import { PinRightIcon } from '@radix-ui/react-icons'
import Logo from '../../assets/AgromovilLogo.svg';

const MainMenu = ({control}: {control: React.ReactElement}) => {
    const {logout} = useUser();
    return (
        <Menu control={control}>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item icon={<PinRightIcon />}
                       onClick={logout}>
                Logout
            </Menu.Item>
        </Menu>
    );
}

const Profile = () => {
    return (
        <MainMenu control={
            <UnstyledButton>
                <Text style={tailwind('text-white')}>
                    AB
                </Text>
            </UnstyledButton>
        }/>
    )
};

export const AppBar = () => {
    return (
        <View style={tailwind('flex flex-row items-center justify-between bg-primary px-2 py-1 h-10')}>
            <View style={tailwind('flex-row items-center')}>
                <View style={tailwind('h-10 w-10')}>
                    <Logo width={40} height={40} fill={'white'}/>
                </View>
                <View style={tailwind('flex-row')}>

                    <Text style={{
                        ...tailwind('text-2xl text-gray-800 text-white'),
                        fontFamily: 'Poppins_700Bold',
                        textTransform: 'uppercase'
                    }}>
                        Agromovil
                    </Text>
                </View>
                <Text style={{
                    ...tailwind('text-2xl text-gray-800 text-white ml-2 opacity-50'),
                    fontFamily: 'Poppins_400Regular',
                }}>
                    Analytics Tool
                </Text>
            </View>
            <View style={tailwind('flex-row items-center')}>
                <Profile />
            </View>
        </View>
    )
}