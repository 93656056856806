import {product} from "simple-statistics";

export type Location = {
    lat: number;
    long: number;
}

export type RoleAgg = {
    "role": string,
    "count": number
}

export type ProductCityResult = {
    location: Location,
    cityName: string,
    qty: number;
}

type Trace = {
    name: string,
    lon: number,
    lat: number,
    count: number,
}

export const maybePluralize = (count: number, noun: string, suffix: string = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const formatOfferMapTrace = (places: ProductCityResult[], isBuyer:boolean, productName:string, unitLabel:string) => {
    const lon = places ? places.map(f => f.location.long) : [];
    const lat = places ? places.map(f => f.location.lat) : [];
    const trace: Trace[] = [];
    const traceText: string[] = [];
    places?.forEach(place => {
        trace.push({
            name: place.cityName,
            lon: place.location.long,
            lat: place.location.lat,
            count: place.qty
        });
        traceText.push(`${place.cityName} ${place.qty}${unitLabel}`);
    });

    const data = [
        {
            type: "scattermapbox",
            name: productName,
            lon,
            lat,
            hoverinfo: "text",
            text: traceText,
            marker: {
                size: trace.map(f => f.count),
                color: isBuyer ? '#F78D1E': '#7CAE75',
                opacity: 0.5,
                sizemode: 'area',
                sizeref: 2. * Math.max(...trace.map(f => f.count)) / (40. ** 2),
                sizemin: 10,
                line: {
                    color: 'white',
                    width: 2
                }
            }
        },
    ];



    return {data};
};

//TODO update to country location not hard coded to tanzania.
export const centerCoord = {
    lat: -6.369000,
    lon: 34.8888
}