import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getBuyingOffersQtyByCity} from "../graphql/queries";
import dayjs from "dayjs";
import {convertDateToSearchableFormat} from "../utils/date";

export interface UseBuyingOffersByCityParams {
    date: string;
    limit: number;
    country: string;
    language: string;
    cooperativeId?: string;
    productId?: string;
    unit?: number;
    minAge?: number;
    maxAge?: number;
    gender?:string;
}


export const useBuyingOffersByCity = (params: UseBuyingOffersByCityParams) => {
    const {
        date,
        limit,
        country,
        language,
        cooperativeId,
        productId,
        unit,
        minAge,
        maxAge,
        gender
    } = params;

    const startDate = convertDateToSearchableFormat(dayjs(date).subtract(1, 'day').toDate());
    const endDate = convertDateToSearchableFormat(dayjs(date).add(1, 'day').toDate());
    return useQuery(['buying-offers-by-city', startDate, endDate, limit, country, language, cooperativeId, productId, minAge, maxAge, gender], async () => {
        const result: any = await API.graphql(graphqlOperation(getBuyingOffersQtyByCity, {
            input: {
                startDate,
                endDate,
                country,
                limit,
                cooperativeId,
                unitId: unit,
                productId,
                language,
                minAge,
                maxAge,
                gender
            }
        }));

        const final = result!.data!.getBuyingOffersQtyByCity.find((locationTick: any) => {
            return dayjs(locationTick.timeStampAsString).isSame(dayjs(new Date(date)), 'day');
        });

        return final?.cities || [];
    });
}