import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {tailwind} from "../tailwind";
import {View} from "react-native";
import * as yup from 'yup';
// @ts-ignore
import bgImage from '../../assets/bg-image-2.jpg';
import {Button, Center, Image, Input, InputWrapper, PasswordInput, Space, Text, Title} from "@mantine/core";
import useUser, {AuthChallenge} from "../hooks/useUser";
import {useLocation, useNavigate} from "react-router-dom";
import Logo from '../../assets/AgromovilLogoVerde.svg';
import {yupResolver} from "@hookform/resolvers/yup";

// ErrorMessage a react component to render the error message
// The error message is rendered if the login function returns an error.
const ErrorMessage = (props: { error?: string }) => {
    const {error} = props;

    return (
        <p style={{...tailwind('text-red-500'), fontFamily: 'Poppins_400Regular'}}>
            {error}
        </p>
    );
};

/*
  LoginForm: A react form component for login with react-hook-form
  The login only has one field phoneNumber
  The phoneNumber is required
  The phoneNumber is a string
 */
const LoginForm = () => {
    const {error, loading, login, forgotPassword} = useUser();
    const {register, handleSubmit} = useForm();
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";
    const onSubmit = handleSubmit((data) => {
        login(data.email, data.password).then(() => {
            navigate(from, {replace: true});
        }).catch((e) => {
            console.log("Error logging in.")
        });
    });

    //return the form
    return (
        <form onSubmit={onSubmit} style={tailwind('p-6')}>
            <InputWrapper
                id="email"
                required
                label="Email Address">
                <Input {...register('email')}/>
            </InputWrapper>
            <Space h="md" />
            <InputWrapper
                id="password"
                required
                label="Password">
                <PasswordInput  {...register('password')}/>
            </InputWrapper>
            <ErrorMessage error={error || ''}/>
            <Space h="lg" />
            <Button type="submit"
                    fullWidth
                    radius={"xl"}
                    loading={loading}>
                {loading ? 'Loading...' : 'Log In'}
            </Button>
            <Space h={"md"}/>
            <Button fullWidth variant="subtle" onClick={() => forgotPassword()}>
                Forgot Password
            </Button>
        </form>
    )
}

const forgotPasswordSchema = yup.object().shape({
    email: yup.string().required(),
}).required();

const ForgotPasswordForm = () => {
    const {error, loading, requestCode, cancelForgotPassword} = useUser();

    const {register, handleSubmit, formState: {errors}} = useForm<{email:string}>({resolver: yupResolver(forgotPasswordSchema)});

    const onSubmit = handleSubmit((data) => {
        requestCode(data.email);
    });

    //return the form
    return (
        <form onSubmit={onSubmit} style={tailwind('p-6')}>
            <Title order={4}>Forgot Password</Title>
            <Space h="md" />
            <Text size={"xs"}>Enter your email address to receive a reset code email.</Text>
            <Space h="lg" />
            <InputWrapper
                id="email"
                required
                label="Email Address">
                <Input {...register('email')}/>
            </InputWrapper>
            <ErrorMessage error={error ||  errors.email?.message || ''}/>
            <Space h="lg" />
            <Button type="submit"
                    fullWidth
                    radius={"xl"}
                    loading={loading}>
                {loading ? 'Loading...' : 'Request Reset Code'}
            </Button>
            <Space h={"md"}/>
            <Button fullWidth variant="subtle" onClick={() => cancelForgotPassword()}>
                Cancel
            </Button>
        </form>
    )
};

interface ResetPasswordFormValues {
    resetCode: string;
    newPassword: string;
    confirmPassword: string;
}

const resetPasswordSchema = yup.object().shape({
    resetCode: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup.string().required(),
}).required();

const ResetPasswordForm = () => {
    const {error, loading, resetPassword, cancelForgotPassword} = useUser();
    const {register, handleSubmit} = useForm<ResetPasswordFormValues>();

    const onSubmit = handleSubmit((data) => {
        resetPassword(data.resetCode, data.newPassword, data.confirmPassword).catch(() => {});
    });

    //return the form
    return (
        <form onSubmit={onSubmit} style={tailwind('p-6')}>
            <Title order={4}>Reset Password</Title>
            <Space h="md" />
            <Text size={"xs"}>Agromovil sent you an email with a reset code. Please enter the code below
            along with your new password.</Text>
            <Space h="lg" />
            <InputWrapper
                id="resetCode"
                required
                label="Reset Code">
                <Input  {...register('resetCode')}/>
            </InputWrapper>
            <Space h="md" />
            <InputWrapper
                id="newPassword"
                required
                label="New Password">
                <PasswordInput  {...register('newPassword')}/>
            </InputWrapper>
            <Space h="md" />
            <InputWrapper
                id="confirmPassword"
                required
                label="Confirm New Password">
                <PasswordInput  {...register('confirmPassword')}/>
            </InputWrapper>
            {error && <ErrorMessage error={error}/>}
            <Space h="lg" />
            <Button type="submit"
                    fullWidth
                    radius={"xl"}
                    loading={loading}>
                {loading ? 'Loading...' : 'Reset Password'}
            </Button>
            <Space h="md" />
            <Button fullWidth variant="subtle" onClick={() => cancelForgotPassword()}>
                Cancel
            </Button>
        </form>
    )
}

const NewPasswordForm = () => {
    const {error, loading, confirmNewPassword} = useUser();
    const {register, handleSubmit} = useForm();
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";
    const onSubmit = handleSubmit((data) => {
        confirmNewPassword(data.newPassword, data.confirmPassword).then(() => {
            navigate(from, {replace: true});
        }).catch((e) => {
            console.log("Error logging in.");
        });
    });

    //return the form
    return (
        <form onSubmit={onSubmit} style={tailwind('p-6')}>
            <InputWrapper
                id="newPassword"
                required
                label="New Password">
                <PasswordInput  {...register('newPassword')}/>
            </InputWrapper>
            <Space h="md" />
            <InputWrapper
                id="confirmPassword"
                required
                label="Confirm New Password">
                <PasswordInput  {...register('confirmPassword')}/>
            </InputWrapper>
            {error && <ErrorMessage error={error}/>}
            <Space h="lg" />
            <Button type="submit"
                    fullWidth
                    radius={"xl"}
                    loading={loading}>
                {loading ? 'Loading...' : 'Set New Password'}
            </Button>
        </form>
    )
}

const LoginImage = () => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const onImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <div style={tailwind("w-full h-full overflow-hidden")}>
            <Image
                src={bgImage}
                alt="Farm"
                fit={"cover"}
                height={window.innerHeight}
                style={tailwind(`w-full ${imageLoaded ? 'opacity-100' : 'opacity-0'}`)}
                onLoad={onImageLoad}
            />
        </div>
    );
}

const Login = () => {
    const {challenge} = useUser();

    let Form = LoginForm;

    switch (challenge) {
        case AuthChallenge.NewPasswordRequired:
            Form = NewPasswordForm;
            break;
        case AuthChallenge.ForgotPassword:
            Form = ForgotPasswordForm;
            break;
        case AuthChallenge.ResetPassword:
            Form = ResetPasswordForm;
            break;
    }

    return (
        <View style={tailwind('flex-row h-full w-full overflow-hidden')}>
            <View style={{...tailwind('w-1/5 z-20 bg-white'), minWidth: 300}}>
                <Center style={tailwind('flex-col')}>
                    <Logo height={200} width={200} fill={'#4FB7DE'}/>
                    <Text size={"xl"}
                          weight={700}
                          style={{
                              fontSize: '2.3rem',
                              color: '#4FB7DE',
                          }}>
                        Analytics Tool
                    </Text>
                </Center>
                <Form/>
            </View>
            <View style={tailwind('flex-1 overflow-hidden')}>
                <LoginImage/>
            </View>
        </View>
    );
};

export default Login;