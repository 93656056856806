import React from 'react';
import {Select} from "@mantine/core";
import {tailwind} from "../tailwind";
import {useCooperativesFetch} from "../hooks/useCooperativesFetch";

export interface CooperativeFilterProps {
    country: string;
    cooperativeId?: string;
    setCooperativeId(cooperativeId: string): void;
}

export const CooperativeFilter = ({country, cooperativeId, setCooperativeId}: CooperativeFilterProps) => {
    const {data: cooperatives, isFetching: isCooperativesFetching} = useCooperativesFetch(country);

    const cooperativeOptions = cooperatives?.map(({id, name}) => ({value: id, label: name})) || [];

    const fullCooperativeOptions = [{value: 'all', label: 'All'}].concat(cooperativeOptions);

    return (<Select
        data={fullCooperativeOptions}
        label={isCooperativesFetching ? "loading..." : "Select cooperative"}
        disabled={isCooperativesFetching}
        style={tailwind('flex-1 mr-2')}
        placeholder="Pick one"
        value={cooperativeId || 'all'}
        onChange={(value: string) => setCooperativeId(value)}
    />)
};