import {useQuery} from "react-query";
import {API, graphqlOperation} from "aws-amplify";
import {getUsersByCity} from "../graphql/queries";

export interface UseUsersByCityParams {
    startDate: string,
    endDate: string,
    limit: number,
    country: string,
    gender?: string,
    cooperativeId?: string
    minAge?: number;
    maxAge?: number;
    productId?: string;
}

export const useUsersByCity = (params: UseUsersByCityParams) => {
    const {startDate, endDate, country, limit, gender, cooperativeId, minAge, maxAge, productId} = params;
    return useQuery(['users-by-city', startDate, endDate, country, gender, cooperativeId, minAge, maxAge, productId], async (): Promise<any> => {
        const results: any = await API.graphql(graphqlOperation(getUsersByCity, {
            input: {
                startDate,
                endDate,
                country,
                limit,
                gender,
                cooperativeId,
                minAge,
                maxAge,
                productId
            }
        }));
        return results?.data?.getUsersByCity;
    });
}