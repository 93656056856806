import React from 'react';
import {View} from 'react-native';
import {tailwind} from "../tailwind";
import {StyledNavLink} from "./StyledNavLink";

// needs a sidebar for navigation that has 2 buttons Home and Dashboard

// Home button will navigate to the Home screen

// Dashboard button will navigate to the Dashboard screen

// Home screen will display a welcome message

const Sidebar = () => {
    return (
        <View style={tailwind('h-full w-full bg-lightbg')}>
            <StyledNavLink to={`/app/home`} key={`home`} label={'Home'} iconName={'home'}/>
            <StyledNavLink to={`/app/dashboard/*`} label={'Dashboard'} key={`dashboard`} iconName={'grid'}/>
        </View>
    );
};

export default Sidebar;